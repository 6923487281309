import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
	loginForm: {
		padding: 3,
		width: '50%'
	},
	loginButton: {
		width: '100%',
		marginTop: 3,
		backgroundColor: '#13b9e9',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 20,
        marginBottom: 3,
        padding: 1
	},
    loginBox: {
        marginLeft: '33%',
        padding: 10
    },
    forgotPasswordStyle: {
        textDecoration: 'none',
		color: '#046da4'
    }
};

function LoginPage() {
	const [inputs, setInputs] = useState({
        username: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { username, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();

    // Reset login status
    useEffect(() => { 
        document.title = "DFQ Vendor - Log in";
        dispatch(userActions.logout()); 
    }, []);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();

        setSubmitted(true);
        if (username && password) {
            dispatch(userActions.login(username, password));
        }
    }

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
            <Container sx={classes.loginBox}>
                <Paper elevation={3} sx={classes.loginForm}>
                        <Typography variant='h3' sx={{color: '#595959', marginTop: 5}}>Login</Typography>
                        <Typography variant='h5' sx={{marginTop: 3, marginBottom: 5}}>Don't have an account? <Link to='/signup' style={{textDecoration: 'none', color: '#13b9e9'}}>Sign up for free!</Link></Typography>
                        <form onSubmit={handleSubmit} style={{textAlign: 'center'}}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                label="Email"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                error={submitted && !username }
                                value={username}
                                onChange={handleChange}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                error={submitted && !password }
                                value={password}
                                onChange={handleChange}
                            />
                            {!loggingIn &&        
                                <>  
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={classes.loginButton}
                                    > 
                                        Log In 
                                    </Button>
                                    <Link to="/forgotpassword" style={classes.forgotPasswordStyle}>Forgot Password</Link>
                                </>   
                                }
                            {loggingIn && <CircularProgress />}
                        </form>
                </Paper>
            </Container>
		</Container>
		<Footer />
		</>
	);
}

export { LoginPage };