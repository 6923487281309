import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { jwtDecode } from "jwt-decode";

export const userActions = {
    login,
    logout,
    signUp,
    getMyAccountDetails,
    updateMyAccountDetails,
    changePassword,
    sendResetPasswordLink,
    validateResetPasswordKey,
    resetPasswordWithKey,
    deleteAccount
};

function login(userID, password) {
    return dispatch => {
        dispatch(request());
        userService.login(userID, password)
            .then(
                async response => {
                    if(response.status === 200){
                        var decodedJwt = jwtDecode(response.data.JwtToken);
                        var encodedJwt = response.data.JwtToken;
                        localStorage.setItem('user', JSON.stringify(decodedJwt));
                        localStorage.setItem('jwtBearer', JSON.stringify(encodedJwt));
                        dispatch(success(decodedJwt));
                        dispatch(alertActions.success('Log in was successful!'));
                        window.history.pushState({}, undefined, `/home`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure() { return { type: userConstants.LOGIN_FAILURE } }
}

function logout() {
    userService.logout();
    return { type: userConstants.USER_LOGOUT };
}

function signUp(data) {
    return dispatch => {
        dispatch(request());
        userService.signUp(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Sign up was successful!'));
                        window.history.pushState({}, undefined, `/accountcreated`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.SIGN_UP_REQUEST } }
    function success() { return { type: userConstants.SIGN_UP_SUCCESS } }
    function failure() { return { type: userConstants.SIGN_UP_FAILURE } }
}

function getMyAccountDetails(userId) {
    return dispatch => {
        dispatch(request());
        userService.getMyAccountDetails(userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        //dispatch(alertActions.success('Found account details!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.GET_MY_ACCOUNT_DETAILS_REQUEST } }
    function success(myAccountDetails) { return { type: userConstants.GET_MY_ACCOUNT_DETAILS_SUCCESS, myAccountDetails } }
    function failure() { return { type: userConstants.GET_MY_ACCOUNT_DETAILS_FAILURE } }
}

function updateMyAccountDetails(data) {
    return dispatch => {
        dispatch(request());
        userService.updateMyAccountDetails(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Account updated successfully!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.UPDATE_MY_ACCOUNT_DETAILS_REQUEST } }
    function success() { return { type: userConstants.UPDATE_MY_ACCOUNT_DETAILS_SUCCESS } }
    function failure() { return { type: userConstants.UPDATE_MY_ACCOUNT_DETAILS_FAILURE } }
}

function changePassword(data) {
    return dispatch => {
        dispatch(request());
        userService.changePassword(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Password changed. Please login with new password.'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
    function success() { return { type: userConstants.CHANGE_PASSWORD_SUCCESS } }
    function failure() { return { type: userConstants.CHANGE_PASSWORD_FAILURE } }
}

function sendResetPasswordLink(data) {
    return dispatch => {
        dispatch(request());
        userService.sendResetPasswordLink(data)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('A link to reset your password has been emailed!'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.SEND_RESET_PASSWORD_LINK_REQUEST } }
    function success() { return { type: userConstants.SEND_RESET_PASSWORD_LINK_SUCCESS } }
    function failure() { return { type: userConstants.SEND_RESET_PASSWORD_LINK_FAILURE } }
}

function validateResetPasswordKey(resetKey) {
    return dispatch => {
        dispatch(request());
        userService.validateResetPasswordKey(resetKey)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure(JSON.stringify(error.response.data.Error.Message)));
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.VALIDATE_RESET_PASSWORD_KEY_REQUEST } }
    function success() { return { type: userConstants.VALIDATE_RESET_PASSWORD_KEY_SUCCESS } }
    function failure(error) { return { type: userConstants.VALIDATE_RESET_PASSWORD_KEY_FAILURE, error } }
}

function resetPasswordWithKey(data, key) {
    return dispatch => {
        dispatch(request());
        userService.resetPasswordWithKey(data, key)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        dispatch(alertActions.success('Password changed. Please login with new password.'));
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.RESET_PASSWORD_WITH_KEY_REQUEST } }
    function success() { return { type: userConstants.RESET_PASSWORD_WITH_KEY_SUCCESS } }
    function failure() { return { type: userConstants.RESET_PASSWORD_WITH_KEY_FAILURE } }
}

function deleteAccount(userId) {
    return dispatch => {
        dispatch(request());
        userService.deleteAccount(userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success());
                        window.history.pushState({}, undefined, `/login`);
                        window.location.reload();
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: userConstants.DELETE_ACCOUNT_REQUEST } }
    function success() { return { type: userConstants.DELETE_ACCOUNT_SUCCESS } }
    function failure() { return { type: userConstants.DELETE_ACCOUNT_FAILURE } }
}