import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	}
};

function SessionExpiredPage() {
    const dispatch = useDispatch();

    // Reset login status
    useEffect(() => { 
		document.title = "DFQ Vendor - Session Expired";
        dispatch(userActions.logout()); 
    }, []);

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			<Typography>Session has expired. Please log in again:</Typography>
			<br />
			<Link to='/login'>Login</Link>
		</Container>
        <Footer />
		</>
	);
}

export { SessionExpiredPage };