import { serviceAreasConstants } from '../_constants';
import { serviceAreasService } from '../_services';
import { alertActions } from './';


export const serviceAreasActions = {
    getUserServiceAreas,
    updateServiceAreas
};

function getUserServiceAreas(userId) {
    return dispatch => {
        dispatch(request());
        serviceAreasService.getUserServiceAreas(userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        //dispatch(alertActions.success('Found your service areas!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: serviceAreasConstants.GET_USER_SERVICE_AREAS_REQUEST } }
    function success(serviceAreas) { return { type: serviceAreasConstants.GET_USER_SERVICE_AREAS_SUCCESS, serviceAreas } }
    function failure() { return { type: serviceAreasConstants.GET_USER_SERVICE_AREAS_FAILURE } }
}

function updateServiceAreas(serviceAreas, userId) {
    return dispatch => {
        dispatch(request());
        serviceAreasService.updateServiceAreas(serviceAreas, userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Service areas updated!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: serviceAreasConstants.UPDATE_SERVICE_AREAS_REQUEST } }
    function success() { return { type: serviceAreasConstants.UPDATE_SERVICE_AREAS_SUCCESS } }
    function failure() { return { type: serviceAreasConstants.UPDATE_SERVICE_AREAS_FAILURE } }
}