import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import backgroundImage from '../../_resources/home_page_background.jpg';
import NavMenu from '../../components/NavMenu';

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    warehouseImageStyle: {
        float: 'right',
        marginLeft: 2
    },
    listStyle: {
        listStyleType: 'none',
        fontSize: 20,
        borderLeft: '4px solid',
        borderColor: '#f0ecec',
        paddingLeft: 22
    },
    toQuoteOntextStyle: {
        marginTop: 2,
        marginBottom: 3
    },
    linkStyle: {
        textDecoration: 'none',
        color: '#428bca'
    },
};

function HelpPage() {

	useEffect(() => { 
		document.title = "DFQ Vendor - Help"; 
	}, []);

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>Help</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        <Container>
                            <Typography>If you need assistance with our website, our support department is here to help. You can reach out to us through various channels for prompt and effective support. You can send us an email at <span style={{fontWeight: 'bold'}}>VendorSupport@DirectFreightQuotes.com</span>, and one of our representatives will respond within 24 hours. We strive to provide the best possible support to ensure your experience with our website is smooth and enjoyable.</Typography>
                        </Container>
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { HelpPage };