import { serviceAreasConstants } from '../_constants';

const initialState = {
    loading: false,
    userServiceAreas: [],
    restOfServiceAreas: [],
};

export function serviceAreas(state = initialState, action) {
    switch(action.type) {
        case serviceAreasConstants.GET_USER_SERVICE_AREAS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case serviceAreasConstants.GET_USER_SERVICE_AREAS_SUCCESS:
            return {
                ...state,
                loading: false,
                userServiceAreas: action.serviceAreas.UserServiceAreas,
                restOfServiceAreas: action.serviceAreas.RestOfServiceAreas
            };
        case serviceAreasConstants.GET_USER_SERVICE_AREAS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case serviceAreasConstants.UPDATE_SERVICE_AREAS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case serviceAreasConstants.UPDATE_SERVICE_AREAS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case serviceAreasConstants.UPDATE_SERVICE_AREAS_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}