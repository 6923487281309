import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { activateAccountActions } from '../../_actions';
import { getUserActivationKey } from '../../_helpers';

function NotActivePage() {
    const user = useSelector(state => state.authentication.user);
    const [activationKey, setActivationKey] = useState("");
    const [loadingData, setLoadingData] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "DFQ Vendor - Not Active"; 
        if(loadingData){
            getActivationKey(user.UserId);
        }
    }, []);

    const getActivationKey = async (userId) => {
        const actKey = await getUserActivationKey(userId);
        setActivationKey(actKey);
        setLoadingData(false);
    }

    function resendActivationKey(){
        if (window.confirm("Your account is not activated. Would you like the activation link resent to your email?")) {
            dispatch(activateAccountActions.resendActivationKey(activationKey));
            navigate('/login');
        }else{
            navigate('/login');
        }
    }

    return (
        <div>
            {!loadingData ?
                resendActivationKey()
            : null}
        </div>
    );
}

export { NotActivePage };