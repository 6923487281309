import React from 'react';

function PrivateRoute({ children }) {
    if(localStorage.getItem('user')){
        if(JSON.parse(localStorage.getItem('user')).Active === 'False'){
            window.history.pushState({}, undefined, `/notactive`);
            window.location.reload();
        }else{
            return <>{children}</>;
        }
    }else{
        window.history.pushState({}, undefined, `/login`);
        window.location.reload();
    }
}

export { PrivateRoute };