import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import XYNYTH_Logo from '../_resources/XYNYTH_Logo.gif';
import Temp_Logo from '../_resources/temp-logo.png';

const pages = [
    { Title: 'Home', Url: '/home'}, 
    { Title: 'Available Loads', Url: '/loads/available'},
    { Title: 'Open Quotes', Url: '/quotes/open'}, 
    { Title: 'Past Quotes', Url: '/quotes/past'},
    { Title: 'Service Areas', Url: '/serviceareas'},
    { Title: 'My Account', Url: '/myaccount'},
    { Title: 'Help', Url: '/help'},
    { Title: 'Log Out', Url: '/'}, 
];

const settings = ['Logout'];

function Header() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const user = useSelector(state => state.authentication.user);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{backgroundColor: '#13b9e9', color: 'white', paddingTop: 3, paddingBottom: 3, paddingLeft: '10%'}}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Link to='/home' style={{textDecoration: 'none', color: '#aade3d', paddingTop: 6}}>
            <img src={Temp_Logo} height={80} alt='logo'/>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <Link key={page.Title} to={page.Url} style={{textDecoration: 'none', color: 'black'}}>
                    <MenuItem key={page.Title} onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page.Title}</Typography>
                    </MenuItem>
                </Link>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', paddingLeft: '5%' } }}>
            {pages.map((page) => (
              <Link key={page.Title} to={page.Url} style={{textDecoration: 'none', color: '#aade3d'}}>
                <Button
                    key={page.Title}
                    //onClick={handleNavMenuColor}
                    sx={{ my: 2, color: page.Url === window.location.pathname ? 'black' : 'white', '&:hover': {color: 'black'}, display: 'block', textTransform: 'none', fontSize: 15, marginRight: 2, fontWeight: 'bold' }}
                >
                    {page.Title}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;