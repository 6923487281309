import { BrowserRouter } from 'react-router-dom'
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from './components/PrivateRoute';
import { LandingPage } from './pages/LandingPage';
import { LoginPage } from './pages/LoginPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { SignUpPage } from './pages/SignUpPage';
import { AccountCreatedPage } from './pages/AccountCreatedPage';
import { ActivateAccountPage } from './pages/ActivateAccountPage';
import { NotActivePage } from './pages/NotActivePage';
import { HomePage } from './pages/HomePage';
import { AboutVendorSystemPage } from './pages/About/AboutVendorSystemPage';
import { AboutAvailableLoadsPage } from './pages/About/AboutAvailableLoadsPage';
import { AboutOpenQuotesPage } from './pages/About/AboutOpenQuotesPage';
import { AboutServiceAreasPage } from './pages/About/AboutServiceAreasPage';
import { VendorManualPage } from './pages/VendorManualPage';
import { AvailableLoadsPage } from './pages/AvailableLoadsPage';
import { LoadDetailsPage } from './pages/LoadDetailsPage';
import { OpenQuotesPage } from './pages/Quotes/OpenQuotesPage';
import { PastQuotesPage } from './pages/Quotes/PastQuotesPage';
import { EditQuotePage } from './pages/Quotes/EditQuotePage';
import { ServiceAreasPage } from './pages/ServiceAreasPage';
import { MyAccountPage } from './pages/MyAccountPage';
import { HelpPage } from './pages/HelpPage';
import { SessionExpiredPage } from './pages/SessionExpiredPage';
import { CustomAlert } from './components/CustomAlert';

function App() {
	return (
		<BrowserRouter basename="/">
			<Routes>
				<Route exact path="/" element={<LandingPage />} title="Landing"/>
				<Route path="/login" element={<LoginPage />} title="Log In"/>
				<Route path="/forgotpassword" element={<ForgotPasswordPage />} title="Forgot Password"/>
				<Route path="/resetpassword/:key" element={<ResetPasswordPage />} title="Reset Password"/>
				<Route path="/signup" element={<SignUpPage />} title="Sign Up"/>
				<Route path="/accountcreated" element={<AccountCreatedPage />} title="Account Created"/>
				<Route path="/activateaccount/:key" element={<ActivateAccountPage />} title="Activate Account"/>
				<Route path="/notactive" element={<NotActivePage />} title="Not Active"/>
				<Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} title="Home"/>
				<Route path="/about/vendorsystem" element={<PrivateRoute><AboutVendorSystemPage /></PrivateRoute>} title="About Vendor System"/>
				<Route path="/about/availableloads" element={<PrivateRoute><AboutAvailableLoadsPage /></PrivateRoute>} title="About Available Loads"/>
				<Route path="/about/openquotes" element={<PrivateRoute><AboutOpenQuotesPage /></PrivateRoute>} title="About Open Quotes"/>
				<Route path="/about/serviceareas" element={<PrivateRoute><AboutServiceAreasPage /></PrivateRoute>} title="About Service Areas"/>
				<Route path="/vendormanual" element={<PrivateRoute><VendorManualPage /></PrivateRoute>} title="Vendor Manual"/>
				<Route path="/loads/available" element={<PrivateRoute><AvailableLoadsPage /></PrivateRoute>} title="Available Loads"/>
				<Route path="/loads/details/:loadId" element={<PrivateRoute><LoadDetailsPage /></PrivateRoute>} title="Load  Details"/>
				<Route path="/quotes/open/:newQuoteId?" element={<PrivateRoute><OpenQuotesPage /></PrivateRoute>} title="Open Quotes"/>
				<Route path="/quotes/past" element={<PrivateRoute><PastQuotesPage /></PrivateRoute>} title="Past Quotes"/>
				<Route path="/quotes/edit/:quoteId" element={<PrivateRoute><EditQuotePage /></PrivateRoute>} title="Edit Quote"/>
				<Route path="/serviceareas" element={<PrivateRoute><ServiceAreasPage /></PrivateRoute>} title="Service Areas"/>
				<Route path="/myaccount" element={<PrivateRoute><MyAccountPage /></PrivateRoute>} title="My Account"/>
				<Route path="/help" element={<PrivateRoute><HelpPage /></PrivateRoute>} title="Help"/>
				<Route path="/sessionexpired" element={<SessionExpiredPage />} title="Session Expired"/>
			</Routes>
			<CustomAlert />
		</BrowserRouter>
	);
}

export default App;