import { loadConstants } from '../_constants';

const initialState = {
    loading: false,
    availableLoads: [],
    loadDetails: null
};

export function load(state = initialState, action) {
    switch(action.type) {
        case loadConstants.GET_AVAILABLE_LOADS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loadConstants.GET_AVAILABLE_LOADS_SUCCESS:
            return {
                ...state,
                loading: false,
                availableLoads: action.availableLoads
            };
        case loadConstants.GET_AVAILABLE_LOADS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case loadConstants.GET_LOAD_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case loadConstants.GET_LOAD_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                loadDetails: action.loadDetails
            };
        case loadConstants.GET_LOAD_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}