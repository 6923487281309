import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));

const initialState = {
    loggedIn: user !== null,
    loading: false,
    user
};

export function authentication(state = initialState, action) {
    switch(action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                loggingIn: true,
                user: action.user
            };
        case userConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                user: action.user
            };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.USER_LOGOUT:
                return {};
        case userConstants.SIGN_UP_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.SIGN_UP_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.SIGN_UP_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}