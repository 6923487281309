import axiosInstance from '../_helpers/axiosInstance';

export const userService = {
    login,
    logout,
    signUp,
    validateActivationKey,
    resendActivationKey,
    getMyAccountDetails,
    updateMyAccountDetails,
    changePassword,
    sendResetPasswordLink,
    validateResetPasswordKey,
    resetPasswordWithKey,
    deleteAccount
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;
//const API_URL = 'https://localhost:7189/api';

function login(userID, password){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/LogIn`,
        data: {
            UserId: userID,
            Password: password
        }
    });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('jwtBearer');
    // localStorage.removeItem('refreshToken');
}

function signUp(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/SignUp`,
        data: {
            FirstName: data.firstName,
            LastName: data.lastName,
            Email: data.email,
            PhoneNumber: data.phoneNumber,
            Password: data.password,
            CompanyName: data.companyName,
            CompanyCountry: data.companyCountry,
            CompanyProvinceState: data.companyProvinceState,
            CompanyCity: data.companyCity,
            CompanyAddress: data.companyAddress,
            CompanyPostalZip: data.companyPostalZip,
            ShipmentType: data.shipmentType,
            CarrierType: data.carrierType,
            TrucksOwned: data.numberOfTrucks,
            Currency: data.currency
        }
    });
}

function validateActivationKey(resetKey){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/ActivationKey/validate`,
        data: {
            ActivationKey: resetKey
        }
    });
}

function resendActivationKey(resetKey){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/ActivationKey/resend`,
        data: {
            ActivationKey: resetKey
        }
    });
}

function getMyAccountDetails(userId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/user/GetMyAccountDetails?UserId=${userId}`
    });
}

function updateMyAccountDetails(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/user/UpdateMyAccountDetails`,
        data: {
            UserId: data.userId,
            FirstName: data.firstName,
            LastName: data.lastName,
            Email: data.email,
            PhoneNumber: data.phoneNumber,
            CompanyName: data.companyName,
            CompanyCountry: data.companyCountry,
            CompanyProvinceState: data.companyProvinceState,
            CompanyCity: data.companyCity,
            CompanyAddress: data.companyAddress,
            CompanyPostalZip: data.companyPostalZip,
            ShipmentType: data.shipmentType,
            CarrierType: data.carrierType,
            TrucksOwned: data.numberOfTrucks,
            Currency: data.currency
        }
    });
}

function changePassword(data){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/password/change`,
        data: {
            UserId: data.userId,
            NewPassword: data.password
        }
    });
}

function sendResetPasswordLink(data){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/password/sendResetLink`,
        data: {
            Email: data.email
        }
    });
}

function validateResetPasswordKey(resetKey){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/password/validateResetKey`,
        data: {
            ResetPasswordKey: resetKey
        }
    });
}

function resetPasswordWithKey(data, key){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/password/resetWithKey`,
        data: {
            ResetPasswordKey: key,
            NewPassword: data.password,
        }
    });
}

function deleteAccount(userId){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/user/delete`,
        data: {
            UserId: userId
        }
    });
}