import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../_actions';
import { compareUtil } from '../../../_helpers';
import { numberWithCommas } from '../../../_helpers';
import { addCapSpace } from '../../../_helpers';
import NumericFormatCustom from '../../../components/NumericFormatCustom';
import { useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import backgroundImage from '../../../_resources/home_page_background.jpg';
import NavMenu from '../../../components/NavMenu';
import Footer from '../../../components/Footer';

const classes = {
    contentContainer: {
        width: '75%'
    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    formInput: {
        width: '100%'
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    datePickerStyle: {
        marginTop: 2,
    },
    circularProgressStyle: {
        marginLeft: '45%', 
        marginTop: 2
    },
    confirmationTextStyle: {
        marginLeft: 1,
        marginTop: 2,
        fontSize: 20
    },
    submitButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 2,
        marginLeft: '40%',
    },
};

const validationSchema = Yup.object().shape({
    bestBasePrice: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    fuelSurcharge: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    totalDropFee: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    currency: Yup.string()
        .required('Currency is required'),
    dateEquipmentAvailable: Yup.date()
        .required('Date is required')
        .typeError('Date is required'),
    equipmentType: Yup.string()
        .required('Equipment type is required'),
    trailerType: Yup.string()
        .required('Trailer type is required'),
    transitDays: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
});

function EditQuotePage() {
    let { quoteId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.quote.loading);
    const quoteDetails = useSelector(state => state.quote.quoteDetails);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema),
        //defaultValues: { 'bestBasePrice': 0, 'fuelSurcharge': 0, 'totalDropFee': 0 }
    });

    const bestBasePrice = useWatch({control, name:'bestBasePrice'});
    const fuelSurcharge = useWatch({control, name:'fuelSurcharge'});
    const totalDropFee = useWatch({control, name:'totalDropFee'});

    useEffect(() => { 
		document.title = "DFQ Vendor - Edit Quote";
        dispatch(quoteActions.getQuoteDetails(quoteId));
	}, []);

    useEffect(() => { 
        if(quoteDetails){
            setValue('bestBasePrice', quoteDetails.BestPrice);
            setValue('fuelSurcharge', quoteDetails.TotalSurcharges);
            setValue('totalDropFee', quoteDetails.ExtraFees);
        }
	}, [quoteDetails]);

    const onSubmit = data => {
        data.quoteId = quoteId;
        data.totalQuote = Number(bestBasePrice) + Number(fuelSurcharge) + Number(totalDropFee);
        if(!data.yourComments){
            data.yourComments = " ";
        }
        dispatch(quoteActions.updateQuoteDetails(data));
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>Edit Quote Details</Typography>
                        {quoteDetails ? 
                        <Grid item xs={12} sm={8}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="bestBasePrice"
                                            control={control}
                                            defaultValue={quoteDetails.BestPrice}
                                            render={({ field }) => 
                                                <TextField 
                                                    label="Your Best Base Price" 
                                                    variant="outlined" 
                                                    margin="normal" 
                                                    size="small"
                                                    sx={classes.formInput} 
                                                    {...field} 
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                        inputComponent: NumericFormatCustom,
                                                    }}
                                                    error={errors.bestBasePrice ? true : false}
                                                />
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.bestBasePrice?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={12} sm={4}>
                                        <Controller
                                            name="fuelSurcharge"
                                            control={control}
                                            defaultValue={quoteDetails.TotalSurcharges}
                                            render={({ field }) => 
                                                <TextField 
                                                    label="Total Surcharge(Fuel + Carbon Tax)" 
                                                    variant="outlined" 
                                                    margin="normal" 
                                                    size="small"
                                                    sx={classes.formInput} 
                                                    {...field} 
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                        inputComponent: NumericFormatCustom,
                                                    }}
                                                    error={errors.fuelSurcharge ? true : false}
                                                />
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.fuelSurcharge?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={12} sm={4}>
                                        <Controller
                                            name="totalDropFee"
                                            control={control}
                                            defaultValue={quoteDetails.ExtraFees}
                                            render={({ field }) => 
                                                <TextField 
                                                    label="Total Extra Fees (Drop Fees)" 
                                                    variant="outlined" 
                                                    margin="normal" 
                                                    size="small"
                                                    sx={classes.formInput} 
                                                    {...field} 
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                        inputComponent: NumericFormatCustom,
                                                    }}
                                                    error={errors.totalDropFee ? true : false}
                                                />
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.totalDropFee?.message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item  xs={12} sm={4}>
                                        <Controller
                                            name="totalQuote"
                                            control={control}
                                            render={({ field }) => 
                                                <TextField 
                                                    disabled
                                                    label="Total Quote" 
                                                    variant="outlined" 
                                                    margin="normal" 
                                                    size="small"
                                                    sx={classes.formInput} 
                                                    {...field} 
                                                    error={errors.totalQuote ? true : false}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                        inputComponent: NumericFormatCustom,
                                                    }}
                                                    value={Number(bestBasePrice) + Number(fuelSurcharge) + Number(totalDropFee)}
                                                />
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.totalQuote?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={12} sm={4}>
                                        <Controller
                                            name="currency"
                                            control={control}
                                            defaultValue={quoteDetails.Currency}
                                            render={({ field }) => 
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="currencyLabel" sx={{marginTop: 2}}>Currency</InputLabel>
                                                    <Select labelId="currencyLabel" id="currency" sx={classes.selectInput} {...field} label="Currency">
                                                        <MenuItem value="CAD">CAD</MenuItem>
                                                        <MenuItem value="USD">USD</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.currency?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <Controller
                                            name="dateEquipmentAvailable"
                                            control={control}
                                            defaultValue={dayjs(quoteDetails.DateEquipmentAvailable)}
                                            render={({ field }) => 
                                                <FormControl fullWidth sx={classes.datePickerStyle}>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            slotProps={{
                                                                textField: {
                                                                    error: false,
                                                                    size: 'small'
                                                                },
                                                            }}
                                                            id="dateEquipmentAvailable"
                                                            {...field}
                                                            error={errors.dateEquipmentAvailable ? true : false}
                                                            label="Date Equipment Available"
                                                        />
                                                    </LocalizationProvider>
                                                </FormControl>
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.dateEquipmentAvailable?.message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item  xs={12} sm={4}>
                                        <Controller
                                            name="equipmentType"
                                            control={control}
                                            defaultValue={quoteDetails.EquipmentType}
                                            render={({ field }) => 
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="equipmentTypeLabel" sx={{marginTop: 2}}>Equipment Type</InputLabel>
                                                    <Select labelId="equipmentTypeLabel" id="equipmentType" sx={classes.selectInput} {...field} label="Equipment Type">
                                                        <MenuItem value="dryVan">Dry Van</MenuItem>
                                                        <MenuItem value="flatBed">Flat Bed</MenuItem>
                                                        <MenuItem value="pupTruck">Pup Truck</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.equipmentType?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={12} sm={4}>
                                        <Controller
                                            name="trailerType"
                                            control={control}
                                            defaultValue={quoteDetails.TrailerType}
                                            render={({ field }) => 
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="trailerTypeLabel" sx={{marginTop: 2}}>Trailer Type</InputLabel>
                                                    <Select labelId="trailerTypeLabel" id="trailerType" sx={classes.selectInput} {...field} label="Trailer Type">
                                                        <MenuItem value="48Or 53Tandem">Tandem - 48' OR 53'</MenuItem>
                                                        <MenuItem value="48Tandem">Tandem - 48'</MenuItem>
                                                        <MenuItem value="53Tandem">Tandem - 53'</MenuItem>
                                                        <MenuItem value="superB">Super B</MenuItem>
                                                        <MenuItem value="48Tridem">Tridem - 48'</MenuItem>
                                                        <MenuItem value="53Tridem">Tridem - 53'</MenuItem>
                                                        <MenuItem value="48Or 53Tridem">Tridem - 48' OR 53'</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.trailerType?.message}
                                        </Typography>
                                    </Grid>
                                    <Grid item  xs={12} sm={4}>
                                        <Controller
                                            name="transitDays"
                                            control={control}
                                            defaultValue={quoteDetails.TransitDays}
                                            render={({ field }) => 
                                                <TextField
                                                    label="Your Transit Days" 
                                                    variant="outlined" 
                                                    margin="normal" 
                                                    size="small"
                                                    type="number"
                                                    sx={classes.formInput} 
                                                    {...field} 
                                                    error={errors.transitDays ? true : false}
                                                />
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.transitDays?.message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="yourComments"
                                            control={control}
                                            defaultValue={quoteDetails.Comments ? quoteDetails.Comments : ""}
                                            render={({ field }) => 
                                                <TextField 
                                                    label="Your Comments (Optional)" 
                                                    variant="outlined" 
                                                    multiline
                                                    rows={3}
                                                    margin="normal" 
                                                    sx={classes.formInput}
                                                    {...field} 
                                                    error={errors.yourComments ? true : false}
                                                />
                                            }
                                        />
                                        <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                            {errors.yourComments?.message}
                                        </Typography>
                                    </Grid>
                                    
                                </Grid>
                                {!loading ?
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={classes.submitButton}
                                > 
                                    Submit
                                </Button>
                                : <CircularProgress sx={classes.circularProgressStyle}/>}
                                {/* <Typography sx={classes.confirmationTextStyle}>Confirmation email will be sent to: <span style={{fontWeight: 'bold'}}>{user.UserId}</span></Typography> */}
                            </form>
                        </Grid>
                        : <LinearProgress />}
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { EditQuotePage };