import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import backgroundImage from '../../../_resources/home_page_background.jpg';
import officeSupplies from  '../../../_resources/about_open_quotes_page_office_supplies.jpg';
import NavMenu from '../../../components/NavMenu';
import Footer from '../../../components/Footer';

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    officeSuppliesImageStyle: {
        float: 'right',
        marginLeft: 2
    },
    seeOpenQuotesTextStyle: {
        marginTop: 2,
        marginBottom: 15
    },
    linkStyle: {
        textDecoration: 'none',
        color: '#428bca'
    },
};

function AboutOpenQuotesPage() {

	useEffect(() => { 
		document.title = "DFQ Vendor - About Open Quotes"; 
	}, []);

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>OPEN QUOTES</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        <Container>
                            <img src={officeSupplies} alt='logo' style={classes.officeSuppliesImageStyle}/>
                            <Typography>This section allows a vendor to see the quotes they have submitted on all current, available loads.  Please note that once a load has been covered and a carrier assigned to it, these quotes are transferred into the Past Quotes. These can be viewed by visiting the your <Link to='/quotes/past' style={classes.linkStyle}>Past Quotes</Link>.</Typography>
                            <Typography sx={classes.seeOpenQuotesTextStyle}>See your <Link to='/quotes/open' style={classes.linkStyle}>Open Quotes</Link> now.</Typography>
                        </Container>
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { AboutOpenQuotesPage };