import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Footer from '../../components/Footer';

const validationSchema = Yup.object().shape({
    email: Yup.string()
        .required('Email is required')
        .max(100, 'Email must not exceed 100 characters')
});

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
    paperStyle: {
        padding: 4,
        width: '50%',
        marginLeft: '25%',
        marginTop: 10,
        marginBottom: 10
    },
    formHeader: {
        textAlign: 'center',
        color: '#308cdc'
    },
    passwordResetButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 1,
        width: '223px'
    },
    cancelButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 1,
        width: '223px'
    }
};

function ForgotPasswordPage() {
    const loading = useSelector(state => state.myAccount.loading);
    const dispatch = useDispatch();

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });
    
    useEffect(() => { 
        document.title = "DFQ Vendor - Forgot Password";
    }, []);

    const onSubmit = data => {
        dispatch(userActions.sendResetPasswordLink(data));
    };

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
            <Container>
                <Paper elevation={3} sx={classes.paperStyle}>
                    <Typography variant="h3" sx={classes.formHeader}>Forgot Password</Typography>
                    <Typography>Please enter your email address and a link will be sent to you to reset you password</Typography>
                    <form onSubmit={handleSubmit(onSubmit)} style={{textAlign: 'center'}}>
                        <Controller
                            name="email"
                            control={control}
                            defaultValue=""
                            render={({ field }) => 
                                <TextField 
                                    label="Email" 
                                    variant="outlined" 
                                    margin="normal" 
                                    size="small"
                                    type="email"
                                    {...field} 
                                    error={errors.email ? true : false}
                                />
                            }
                        />
                        {!loading ? 
                        <>
                        <Typography variant="inherit" color="error" style={{textAlign:'center'}}>
                            {errors.email?.message}
                        </Typography>
                        <Button type="submit" variant="contained" sx={classes.passwordResetButton}>Request New Password</Button>
                        <br />
                        <Link to="/login"><Button color="error" variant="outlined" sx={classes.cancelButton}>Cancel</Button></Link>
                        </>
                        : <LinearProgress />}
                    </form>
                </Paper>
            </Container>
		</Container>
		<Footer />
		</>
	);
}

export { ForgotPasswordPage };