import axiosInstance from '../_helpers/axiosInstance';

export const loadService = {
    getAvailableLoads,
    getLoadDetails
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function getAvailableLoads(userId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/Load/AvailableLoads?UserId=${userId}`
    });
}

function getLoadDetails(loadId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/Load/Details?LoadId=${loadId}`
    });
}