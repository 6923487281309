import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { quoteActions } from '../../../_actions';
import { compareUtil } from '../../../_helpers';
import { numberWithCommas } from '../../../_helpers';
import { addCapSpace } from '../../../_helpers';
import { useNavigate, useParams } from "react-router-dom";
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import dayjs from 'dayjs';
import { visuallyHidden } from '@mui/utils';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import backgroundImage from '../../../_resources/home_page_background.jpg';
import NavMenu from '../../../components/NavMenu';
import Footer from '../../../components/Footer';

const headCells = [
    {id: 'QuoteId', numeric: true, label: 'Quote Id'},
    {id: 'Submitted', numeric: false, label: 'Submitted'},
    {id: 'LoadNo', numeric: true, label: 'Load No'},
    {id: 'Pickup', numeric: false, label: 'Pickup'},
    {id: 'Delivery', numeric: false, label: 'Delivery'},
    {id: 'TotalPrice', numeric: true, label: 'Total Price'},
    {id: 'Currency', numeric: false, label: 'Currency'},
    {id: 'Equipment', numeric: false, label: 'Equipment'},
    {id: 'AvailableDate', numeric: false, label: 'Available Date'},
    {id: 'TrailerType', numeric: false, label: 'Trailer Type'},
    {id: 'TransitDays', numeric: true, label: 'Transit Days'},
];

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    tableStyle: {
        //whiteSpace: 'nowrap',
        maxHeight: 335,
    },
    tableHeaderColumnStyle: {
        backgroundColor: '#2D54DA',
        border: '1px solid #26B9F5',
        padding: 1
    },
	tableHeaderStyle: {
		fontWeight: 'bold',
        color: 'white !important',
        fontSize: 14,
        "&:hover": {
            color: 'white',
        },
        '& .MuiTableSortLabel-icon': {
            color: 'white !important',
        },
	},
    tableBodyColumnStyle: {
        border: '1px solid #26B9F5',
        padding: 1,
    },
    highlightedRowStyle: {
        cursor: 'pointer',
        backgroundColor: 'white',
        "&:hover": {
            backgroundColor: 'white',
        },
        animation: 'fadeIt 2s ease-in-out',
        '@keyframes fadeIt': {
            '0%':{
                backgroundColor: '#1ECEF5'
            },
            '50%':{
                backgroundColor: '#2D54DA'
            },
            '100%':{
                backgroundColor: '#D3E4FC'
            },
        },
    },
    tableBodyStyle: {
        cursor: 'pointer',
        backgroundColor: '#white',
        "&:hover": {
            backgroundColor: 'white',
        },
	},
};

function OpenQuotesPage() {
    let { newQuoteId } = useParams();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('Submitted');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.quote.loading);
    const openQuotes = useSelector(state => state.quote.openQuotes);

    useEffect(() => { 
		document.title = "DFQ Vendor - Open Quotes";
        dispatch(quoteActions.getOpenQuotes(user.UserId));
	}, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const onRequestSort = (event, property) => {
        handleRequestSort(event, property);
    };

    const openEditQuotePage = (quote) => {
        console.log(quote);
        navigate(`/quotes/edit/${quote.QuoteId}`);
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>Open Quotes</Typography>
                        <Typography sx={{float: 'right', fontWeight: 'bold'}}>Click on a row to view and edit quote details</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        <TableContainer sx={classes.tableStyle}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {headCells.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={'left'}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                                sx={classes.tableHeaderColumnStyle}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.id)}
                                                    sx={classes.tableHeaderStyle}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {openQuotes && openQuotes.length > 0 ?
                                    compareUtil.stableSort(openQuotes, compareUtil.getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover sx={row.QuoteId == newQuoteId ? classes.highlightedRowStyle: classes.tableBodyStyle} key={row.QuoteId} onClick={() => openEditQuotePage(row)}>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.QuoteId}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.Submitted ? dayjs(row.Submitted).format('MMM DD, YYYY') : ''}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.LoadNo}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.Pickup}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.Delivery}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{numberWithCommas(row.TotalPrice)}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.Currency}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{addCapSpace(row.Equipment)}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.AvailableDate ? dayjs(row.AvailableDate).format('MMM DD, YYYY') : ''}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{addCapSpace(row.TrailerType)}</TableCell>
                                            <TableCell align="left" sx={classes.tableBodyColumnStyle}>{row.TransitDays}</TableCell>
                                        </TableRow>
                                    ))
                                    : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={openQuotes.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { OpenQuotesPage };