import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import backgroundVideo from '../../_resources/Landing-Page-Vid.mp4';
import Box from '@mui/material/Box';
import testPic1 from '../../_resources/test-pic-1.png';
import testPic2 from '../../_resources/test-pic-2.png';
import testPic3 from '../../_resources/test-pic-3.png';
import testPic4 from '../../_resources/test-pic-4.png';
import testPic5 from '../../_resources/test-pic-5.png';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './imageSlider.css';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import Footer from '../../components/Footer';

const classes = {
    pageContainer: {
        //backgroundColor: '#f2f5f7',
        backgroundColor: '#13b9e9',
        overflow: 'hidden',
        //paddingBottom: 5
    },
    videoContainer: {
        height: '600px',
        width: '100vw',
        position: 'relative'
    },
    videoStyle: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        objectFit: 'cover',
        zIndex: 0,
        opacity: 50
    },
    videoTextStyle: {
        color: 'white',
        zIndex: 1,
        position: 'relative',
        textAlign: 'center',
        paddingTop: 15,
        fontSize: 120, 
        fontWeight: 'bold'
    },
    buttonBox: {
        textAlign: 'center',
        marginTop: 5
	},
    loginButton: {
		//backgroundColor: '#308cdc',
        backgroundColor: '#13b9e9',
        color: 'white',
		marginLeft: 2,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 20,
        '&:hover': {
            backgroundColor: '#13b9e9'
        }
    },
    signUpButton: {
        //backgroundColor: '#00ab41',
        backgroundColor: 'white',
        color: '#13b9e9',
		marginLeft: 2,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 20,
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    slideContainer: {
        width: '50%',
        paddingTop: 50,
        display: 'block',
        margin: 'auto',
        paddingBottom: 50
    },
    imageStyle: {
        display: 'block',
        margin: 'auto'
    },
    headerText: {
        textAlign: 'center',
        fontSize: 40,
        fontWeight: 'bold',
        color: 'white'
    },
    textStyle: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        color: 'white'
    },
    gridImage: {
        width: '100%',
        height: '100%'
    },
    gridIcon: {
        color: '#13b9e9',
        marginTop: 15,
        marginLeft: 10,
        fontSize: 80
    },
    gridInfoText: {
        color: '#13b9e9',
        fontWeight: 'bold',
        paddingLeft: 10,
    },
    gridInfoSubText: {
        color: '#13b9e9',
        paddingLeft: 10,
        paddingTop: 3,
        fontSize: 22,
        fontWeight: 'bold'
    },
    listStyle: {
        marginLeft: 50,
        color: 'white',
        fontSize: 18
    },
    gridButton: {
        backgroundColor: '#13b9e9',
        color: 'white',
        marginTop: 5,
		marginLeft: 10,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 22,
        '&:hover': {
            backgroundColor: '#13b9e9',
        }
    },
    secondGridHeaderText: {
        fontWeight: 'bold', 
        textAlign: 'center',
        paddingTop: 7,
        color: 'white'
    },
    secondGridIcon: {
        color: 'white',
        marginTop: 5,
        marginLeft: 10,
        fontSize: 80
    },
    secondGridText: {
        paddingLeft: 10,
        color: 'white'
    },
    secondGridSubText: {
        paddingLeft: 10,
        paddingTop: 5,
        paddingBottom: 7,
        fontSize: 20,
        color: 'white'
    },
    lastSignUpButton: {
        backgroundColor: '#13b9e9',
        color: 'white',
        marginTop: 5,
		marginLeft: 10,
		paddingLeft: 3,
		paddingRight:3,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 22,
        '&:hover': {
            backgroundColor: '#13b9e9',
        },
        left: '33%'
    }
};

function LandingPage() {
    const dispatch = useDispatch();

    // Reset login status
	useEffect(() => { 
		document.title = "DFQ Vendor - Landing";
		dispatch(userActions.logout()); 
	}, []);

    var settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <>
        <CssBaseline />
        <Container maxWidth={false} disableGutters sx={classes.pageContainer}>
            <div style={classes.videoContainer}>
                <video className='videoTag' autoPlay loop muted style={classes.videoStyle}>
                    <source src={backgroundVideo} type='video/mp4' />
                </video>
                <Typography
                    sx={classes.videoTextStyle}
                >
                    Direct Freight Quotes
                </Typography>
                <Box sx={classes.buttonBox}>
                    <Link to='/login'><Button variant="contained" sx={classes.loginButton}>Log In</Button></Link>
                    <Link to='/signup'><Button variant="contained" sx={classes.signUpButton}>Sign Up</Button></Link>
                </Box>
            </div>
            <Container>
                <Typography variant='h3' sx={classes.secondGridHeaderText}>Why Vendors Choose Direct Freight Quotes</Typography>
                <Grid container>
                    <Grid item xs={12} sm={4}>
                        <LocalAtmOutlinedIcon sx={classes.secondGridIcon}/>
                        <Typography variant='h4' sx={classes.secondGridText}>Maximize Earnings</Typography>
                        <Typography sx={classes.secondGridSubText}>Drive your business forward by keeping your trucks full on the lanes you like to run</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <HandshakeOutlinedIcon sx={classes.secondGridIcon}/>
                        <Typography variant='h4' sx={classes.secondGridText}>Reduce Hassle</Typography>
                        <Typography sx={classes.secondGridSubText}>Make your job easier, cutting down operating costs and boosting profits</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <LocalShippingOutlinedIcon sx={classes.secondGridIcon}/>
                        <Typography variant='h4' sx={classes.secondGridText}>See All the Loads</Typography>
                        <Typography sx={classes.secondGridSubText}>Get instant access to winning freight from companies across North America</Typography>
                    </Grid>
                </Grid>
            </Container>
            <Grid container sx={{backgroundColor: 'white'}}>
                <Grid item xs={12} sm={6}>
                    <HandshakeOutlinedIcon sx={classes.gridIcon}/>
                    <Typography variant='h3' sx={classes.gridInfoText}>Haul Without the Hassle</Typography>
                    <Typography sx={classes.gridInfoSubText}>With everything done easily online, you’ll spend less time chasing freight and more time on what matters to your business</Typography>
                    <ul style={classes.listStyle}>
                        <li style={{color: '#13b9e9'}}><span style={{color: 'black'}}>Find and manage work right from your phone or computer</span></li>
                        <li style={{color: '#13b9e9'}}><span style={{color: 'black'}}>See facility names, addresses, and ratings on every load</span></li>
                        <li style={{color: '#13b9e9'}}><span style={{color: 'black'}}>Receive invoices through email to streamline bookkeeping</span></li>
                    </ul>
                    <Link to='/signup'><Button variant="contained" sx={classes.gridButton}>Sign Up</Button></Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <img src={testPic4} style={classes.gridImage} alt='image4'/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <img src={testPic5} style={classes.gridImage} alt='image5'/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalShippingOutlinedIcon sx={classes.gridIcon}/>
                    <Typography variant='h3' sx={classes.gridInfoText}>Loads for All</Typography>
                    <Typography sx={classes.gridInfoSubText}>Carriers of all sizes can sign up and quote on their first loads in just minutes</Typography>
                    <ul style={classes.listStyle}>
                        <li style={{color: '#13b9e9'}}><span style={{color: 'black'}}>Get free access to thousands of nationwide loads for all shipment types</span></li>
                        <li style={{color: '#13b9e9'}}><span style={{color: 'black'}}>Get automatic alerts when loads are available on your preferred lanes</span></li>
                        <li style={{color: '#13b9e9'}}><span style={{color: 'black'}}>Book it all online and overcome the pitfalls of phone negotiations like haggling, and language barriers</span></li>
                    </ul>
                    <Link to='/signup'><Button variant="contained" sx={classes.gridButton}>Find Loads</Button></Link>
                </Grid>
            </Grid>
            <div className="image-slider-container" style={classes.slideContainer}>
                <Slider {...settings}>
                    <div>
                        <Typography sx={classes.headerText}>Easy to Book</Typography>
                        <img src={testPic1} height={500} alt='slideImage1' style={classes.imageStyle}/>
                        <Typography sx={classes.textStyle}>DFQ connects truckers with shippers, offering a platform to quickly send quotes for loads. Simplify logistics, increase earnings, and secure jobs efficiently. Maximize your truck's potential with our user-friendly, profitable service</Typography>
                    </div>
                    <div>
                        <Typography sx={classes.headerText}>Manage and Track</Typography>
                        <img src={testPic2} height={500} alt='slideImage2' style={classes.imageStyle}/>
                        <Typography sx={classes.textStyle}>Our site is mobile responsive, fast, and easy to use. Enjoy seamless navigation, quick load times, and a user-friendly interface designed for an optimal browsing experience</Typography>
                    </div>
                    <div>
                        <Typography sx={classes.headerText}>Get Expert Support</Typography>
                        <img src={testPic3} height={500} alt='slideImage3' style={classes.imageStyle}/>
                        <Typography sx={classes.textStyle}>DFQ tailors to truckers' needs because we understand what matters to them: reliability, efficiency, and maximizing earnings. Trust us for a better trucking experience</Typography>
                    </div>
                </Slider>
            </div>
            <Grid container sx={{backgroundColor: 'white', paddingRight: 8}}>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h3' sx={{color: '#13b9e9', paddingTop: 25, paddingLeft: 15, paddingBottom: 5}}>Earning more per load has never been so easy</Typography>
                    <Typography sx={{color: 'black', paddingLeft: 15, fontSize: 25}}>Work with a provider who can pay you more per load and provide access to high-paying partial freight</Typography>
                    <Link to='/signup'><Button variant="contained" sx={classes.lastSignUpButton}>Sign Up</Button></Link>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h4' sx={{color: '#13b9e9', paddingTop: 15, paddingLeft: 15, paddingBottom: 5}}>Earn more, wait less</Typography>
                    <Typography sx={{color: 'black', paddingLeft: 15, fontSize: 20}}>Our easier-to-haul shared truckloads minimize out-of-route mileage and can pay $200 more per day than a traditional truckload. Plus, our tailored load suggestions help keep you moving and earning</Typography>
                    <Typography variant='h4' sx={{color: '#13b9e9', paddingTop: 10, paddingLeft: 15, paddingBottom: 5}}>Keep your trucks full</Typography>
                    <Typography sx={{color: 'black', paddingLeft: 15, fontSize: 20, paddingBottom: 10}}>In addition to our truckload and LTL marketplace, we offer access to more partial freight options than anyone else. By booking shared truckloads, you maximize your trailer space with loads that pay an additional $0.20 per mile</Typography>
                </Grid>
            </Grid>
        </Container>
        {/* <Footer /> */}
        </>
    );
}

export { LandingPage };