import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import backgroundImage from '../../../_resources/home_page_background.jpg';
import truckImage from '../../../_resources/stock_truck.jpg';
import NavMenu from '../../../components/NavMenu';
import Footer from '../../../components/Footer';

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    truckImageStyle: {
        float: 'right',
        marginLeft: 2
    },
    listHeader: {
        fontWeight: 'bold',
        marginTop: 3
    },
    linkStyle: {
        textDecoration: 'none',
        color: '#428bca'
    },
    gettingStartedStyle: {
        marginTop: 5,
        marginBottom: 3
    }
};

function AboutVendorSystemPage() {

	useEffect(() => { 
		document.title = "DFQ Vendor - About Vendor System"; 
	}, []);

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>DFQ's Online Freight Vendor Quote System</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        <Container>
                            <img src={truckImage} alt='logo' height={200} style={classes.truckImageStyle}/>
                            <Typography><span style={{fontWeight: 'bold'}}>DFQ Corp.</span> is a large purchaser of freight and freight services throughout North America. <span style={{fontWeight: 'bold'}}>DFQ’s Online Freight Vendor Quote System</span>, was created so we could communicate quickly, easily and clearly with our freight vendors. We developed an easy to use system to notify vendors of available loads requiring freight services and allow them to quote on any or all available loads.  This spot quote information is relayed immediately to our logistics department.</Typography>
                            <Typography sx={classes.listHeader}>Here's how it works:</Typography>
                            <ol>
                                <li>Freight vendors register with us and specify which provinces and states they offer freight services. <span style={{fontStyle: 'italic'}}>(Please see more on this in the Service Areas section)</span></li>
                                <li>Once an available load is created by DFQ, a notification is emailed to all vendors registered for the corresponding service areas.</li>
                                <li>Vendors log on to our system and, under the <Link to='/loads/available' style={classes.linkStyle}>Available Loads section</Link>, will find all the specific information on the current available loads.</li>
                                <li><Link to='/loads/available' style={classes.linkStyle}>To quote on an available load</Link>, vendors select the Load ID and a quote sheet will open up.  By quoting on an available load, we know the vendor’s current pricing and that they have equipment available.</li>
                                <li>Once the quote is entered, the vendor and DFQ logistics department will both receive and email confirming the information.</li>
                                <li>Once DFQ agrees on a specific quote, that vendor will receive a confirmation email or phone call.</li>
                                <li>DFQ then sends a Freight Purchase Order along with Shipping Instructions detailing all the information needed for the shipment.</li>
                            </ol>
                            <Typography sx={classes.gettingStartedStyle}><span style={{fontWeight: 'bold'}}>Getting started:</span> The first step for freight vendors is to let DFQ know which areas they offer services.  Visit the <Link to='/serviceareas' style={classes.linkStyle}>Service Areas section</Link> to specify which areas you offer freight services, by province and state.</Typography>
                        </Container>
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { AboutVendorSystemPage };