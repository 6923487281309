import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { provinceList } from '../../_helpers';
import { stateList } from '../../_helpers';
import { phoneNumberAutoFormat } from '../../_helpers';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Stack from '@mui/material/Stack';
import Footer from '../../components/Footer';

const classes = {
	pageContainer: {
		backgroundColor: '#f2f5f7',
		overflow: 'hidden'
	},
	formHeader: {
		textAlign: 'center',
		color: '#13b9e9',
		marginTop: '1%',
		fontWeight: 'bold'
	},
	formSubHeaders: {
		color: '#13b9e9',
		fontWeight: 'bold'
	},
	signUpForm: {
		// marginLeft: '33%',
		// marginRight: '33%',
		marginLeft: '30%',
		marginRight: '10%',
		// marginTop: '1%',
		marginTop: '10%',
		marginBottom: '2%',
		padding: 2,
		//width: '33%'
	},
	formRow: {
        display: 'inline-flex',
        width: '100%'
    },
	formInput: {
        width: '100%',
    },
	selectInput: {
		width: '100%',
		marginTop: 2,
	},
	signUpButton: {
		width: '100%',
		marginTop: 3,
		backgroundColor: '#13b9e9',
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
	},
	reviewText: {
		// marginLeft: '33%',
		// marginRight: '33%',
		marginLeft: '30%',
		marginRight: '10%',
		marginBottom: '5%',
		fontWeight: 'bold'
	}
};

const invalidDomains = ['gmail', 'yahoo', 'hotmail', 'outlook', 'icloud', 'aol', 'msn'];

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
	email: Yup.string()
        .required('Email is required')
		.matches(/.+@.+\..+/, 'Please enter a valid email')
		.test(
			'Unauthorized Email Domain',
			'An unauthorized domain was entered. Please use your work email and avoid personal email services',
			(value) => {
				const currentDomain = value.substring(
					value.indexOf('@') + 1,
					value.indexOf('.')
				);

				return !invalidDomains.includes(currentDomain);
			}
		),
	phoneNumber: Yup.string()
        .required('Phone number is required'),
	password: Yup.string()
        .required('Password is required')
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
	confirmPassword: Yup.string()
        .required('Confirm password is required')
		.oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
	companyName: Yup.string()
        .required('Company name is required'),
    companyCountry: Yup.string()
        .required('Country is required'),
	companyProvinceState: Yup.string()
        .required('Province/State is required'),
	companyCity: Yup.string()
        .required('City is required'),
	companyAddress: Yup.string()
        .required('Company address is required'),
	companyPostalZip: Yup.string()
        .required('Postal/zip code is required'),
	shipmentType: Yup.string()
		.required('Shipment type is required'),
	carrierType: Yup.string()
		.required('Carrier type is required'),
	currency: Yup.string()
		.required('Currency is required'),
});

function SignUpPage() {
	const [selectedCountry, setSelectedCountry] = useState('Canada');
	const [selectedCarrierType, setSelectedCarrierType] = useState('');
	const dispatch = useDispatch();
	const loading = useSelector(state => state.authentication.loading);

	const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });

	useEffect(() => { 
		document.title = "DFQ Vendor - Sign up";
		if(selectedCountry){
            renderProvinceStateList();
        }
    }, [selectedCountry]);

	const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}
    };

	const onSubmit = data => {
        dispatch(userActions.signUp(data));
    };

	const onPhoneNumberChange = (input) => {
        let formattedNumber = phoneNumberAutoFormat(input.target.value);
        setValue(input.target.name, formattedNumber);
    };

	return (
		<>
		<CssBaseline />
		<Container maxWidth={false} disableGutters sx={classes.pageContainer}>
			{/* <Typography variant="h2" sx={classes.formHeader}>Vendor Sign Up</Typography> */}
			<Grid container>
				<Grid item xs={12} sm={6}>
					<Paper elevation={3} sx={classes.signUpForm}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Typography variant='h5' sx={classes.formSubHeaders}>User Info</Typography>
							<Grid container spacing={1} sx={classes.formRow}>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="firstName"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="First Name" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.firstName ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.firstName?.message}
									</Typography>
								</Grid>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="lastName"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Last Name" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.lastName ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.lastName?.message}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1} sx={classes.formRow}>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="email"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Email" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.email ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.email?.message}
									</Typography>
								</Grid>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="phoneNumber"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Phone Number" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.phoneNumber ? true : false}
												onChange={(e) => {
													onPhoneNumberChange(e);
												}}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.phoneNumber?.message}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1} sx={classes.formRow}>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="password"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Password" 
												type="password" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.password ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.password?.message}
									</Typography>
								</Grid>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="confirmPassword"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Confirm Password" 
												type="password" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.confirmPassword ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.confirmPassword?.message}
									</Typography>
								</Grid>
							</Grid>
							<Typography variant='h5' sx={classes.formSubHeaders}>Company Info</Typography>
							<Grid container spacing={1} sx={classes.formRow}>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="companyName"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Company Name" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.companyName ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.companyName?.message}
									</Typography>
								</Grid>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="companyCountry"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Country"
												variant="outlined" 
												margin="normal" 
												size="small"
												select
												sx={classes.formInput} 
												{...field} 
												error={errors.companyCountry ? true : false}
												onChange={(e) => {
													field.onChange(e); 
													setSelectedCountry(e.target.value); 
													setValue('companyProvinceState', '');
													if (submitCount > 0)
														trigger('companyProvinceState');
												}}
											>
												<MenuItem value="Canada">Canada</MenuItem>
												<MenuItem value="USA">USA</MenuItem>
											</TextField>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.companyCountry?.message}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1} sx={classes.formRow}>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="companyProvinceState"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Province/State"
												variant="outlined" 
												margin="normal" 
												size="small"
												select
												sx={classes.formInput} 
												{...field} 
												error={errors.companyProvinceState ? true : false}
											>
												{renderProvinceStateList()}
											</TextField>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.companyProvinceState?.message}
									</Typography>
								</Grid>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="companyCity"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Company City" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.companyCity ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.companyCity?.message}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1} sx={classes.formRow}>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="companyAddress"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Company Address" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.companyAddress ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.companyAddress?.message}
									</Typography>
								</Grid>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="companyPostalZip"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<TextField 
												label="Company Postal/Zip Code" 
												variant="outlined" 
												margin="normal" 
												size="small"
												sx={classes.formInput} 
												{...field} 
												error={errors.companyPostalZip ? true : false}
											/>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.companyPostalZip?.message}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1} sx={classes.formRow}>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="shipmentType"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<FormControl fullWidth size="small">
												<InputLabel id="shipmentTypeLabel" sx={{marginTop: 2}}>Shipment Type</InputLabel>
												<Select labelId="shipmentTypeLabel" id="shipmentType" sx={classes.selectInput} {...field} label="Shipment Type">
													<MenuItem value="tlRoad">TL (Full Truckload)</MenuItem>
													<MenuItem value="ltl">LTL (Less Than Truckload)</MenuItem>
													<MenuItem value="both">Both (TL & LTL)</MenuItem>
												</Select>
											</FormControl>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.shipmentType?.message}
									</Typography>
								</Grid>
								<Grid item  xs={12} sm={6}>
									<Controller
										name="carrierType"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<FormControl fullWidth size="small">
												<InputLabel id="carrierTypeLabel" sx={{marginTop: 2}}>Carrier Type</InputLabel>
												<Select labelId="carrierTypeLabel" id="carrierType" sx={classes.selectInput} {...field} label="Carrier Type"
														onChange={(e) => {
															field.onChange(e); 
															setSelectedCarrierType(e.target.value); 
															// setValue('companyProvinceState', '');
															// if (submitCount > 0)
															// 	trigger('companyProvinceState');
														}}
												>
													<MenuItem value="assetBased">Asset Based</MenuItem>
													<MenuItem value="broker">Broker</MenuItem>
												</Select>
											</FormControl>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.carrierType?.message}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={1} sx={classes.formRow}>
								{selectedCarrierType === 'assetBased' ? 
									<Grid item  xs={12} sm={6}>
										<Controller
											name="numberOfTrucks"
											control={control}
											defaultValue=""
											render={({ field }) => 
												<TextField 
													label="Number of Trucks Owned" 
													variant="outlined" 
													margin="normal" 
													size="small"
													type="number"
													sx={classes.formInput} 
													{...field} 
													error={errors.numberOfTrucks ? true : false}
												/>
											}
										/>
										<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
											{errors.numberOfTrucks?.message}
										</Typography>
									</Grid>
								: null}
								<Grid item  xs={12} sm={6}>
									<Controller
										name="currency"
										control={control}
										defaultValue=""
										render={({ field }) => 
											<FormControl fullWidth size="small">
												<InputLabel id="currencyLabel" sx={{marginTop: 2}}>Currency</InputLabel>
												<Select labelId="currencyLabel" id="currency" sx={classes.selectInput} {...field} label="Currency">
													<MenuItem value="CAD">CAD</MenuItem>
													<MenuItem value="USD">USD</MenuItem>
												</Select>
											</FormControl>
										}
									/>
									<Typography variant="inherit" color="error" style={{textAlign:'left'}}>
										{errors.currency?.message}
									</Typography>
								</Grid>
							</Grid>
							{!loading &&        
								<>  
									<Button
										type="submit"
										variant="contained"
										sx={classes.signUpButton}
									> 
										Sign Up
									</Button>
								</>   
								}
							{loading && <CircularProgress sx={{marginTop: 2, marginLeft: '45%'}}/>}
						</form>
					</Paper>
					<Typography sx={classes.reviewText}>Once your form is submitted, you will be sent an email to confirm your email address and activate your account</Typography>
				</Grid>
				<Grid item xs={12} sm={6}>
					<Typography variant='h3' sx={{marginTop: '20%'}}>Create a <span style={{color: '#13b9e9'}}>FREE</span> Account</Typography>
					<Typography variant='h3'> Get access to loads in seconds</Typography>
					<Typography variant='h3' sx={{marginTop: '10%'}}> DFQ is right for you if:</Typography>
					<Stack alignItems="center" direction="row" gap={2} style={{marginTop: 30}}>
						<CheckCircleIcon sx={{color: '#13b9e9', fontSize: 30}}/>
						<Typography variant='h5'>You ship less than truckload (LTL) or full truckload</Typography>
					</Stack>
					<Stack alignItems="center" direction="row" gap={2} style={{marginTop: 10}}>
						<CheckCircleIcon sx={{color: '#13b9e9', fontSize: 30}}/>
						<Typography variant='h5'>You ship regularly in the USA, Canada, and cross-border</Typography>
					</Stack>
					<Stack alignItems="center" direction="row" gap={2} style={{marginTop: 10}}>
						<CheckCircleIcon sx={{color: '#13b9e9', fontSize: 30}}/>
						<Typography variant='h5'>DFQ is a solution for businesses. Ideal for manufacturers, distributors, wholesalers, retailers, importers & exporters</Typography>
					</Stack>
				</Grid>
			</Grid>
		</Container>
		<Footer />
		</>
	);
}

export { SignUpPage };