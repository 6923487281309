import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import backgroundImage from '../../../_resources/home_page_background.jpg';
import globeImage from  '../../../_resources/about_service_areas_page_globe.jpg';
import NavMenu from '../../../components/NavMenu';
import Footer from '../../../components/Footer';

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    globeImageStyle: {
        float: 'right',
        marginLeft: 2
    },
    serviceAreasTextStyle: {
        marginTop: 2,
        marginBottom: 15
    },
    linkStyle: {
        textDecoration: 'none',
        color: '#428bca'
    },
};

function AboutServiceAreasPage() {

	useEffect(() => { 
		document.title = "DFQ Vendor - About Service Areas"; 
	}, []);

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>SERVICE AREAS</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        <Container>
                            <img src={globeImage} alt='logo' style={classes.globeImageStyle}/>
                            <Typography>Through our easy <span style={{fontWeight: 'bold'}}>3-step process</span>, each vendor can specify which provinces and states they service. By doing so, each vendors ensures they will receive notifications when loads are available in their specified service areas. Please note that service areas can be changed or updated at anytime as your business changes. However, if service areas are <span style={{color: 'red'}}>NOT</span> specified, email notifications will not be sent out.</Typography>
                            <Typography sx={classes.serviceAreasTextStyle}><Link to='/serviceareas' style={classes.linkStyle}>Register your Service Areas</Link> now.</Typography>
                        </Container>
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { AboutServiceAreasPage };