import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceAreasActions } from '../../_actions';
import { loadActions } from '../../_actions';
import { quoteActions } from '../../_actions';
import { userActions } from '../../_actions';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import truckBanner from '../../_resources/home_page_truck_banner.jpg';
import usaFlag from '../../_resources/united-states-apple.png';
import canadaFlag from '../../_resources/canada-apple.png';
import stockTruck from '../../_resources/stock_truck.jpg';
import NavMenu from '../../components/NavMenu';
import { LocalShipping } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import SquareIcon from '@mui/icons-material/Square';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

const classes = {
    pageContainer: {
        //backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh',
    },
    contentContainer: {
       
    },
    welcomeContent: {
        padding: 50,
        backgroundImage: `url(${truckBanner})`,
        color: 'white',
        width: '97%',
        marginLeft: '1.5%',
    },
    learnMoreButton: {
        textTransform: 'none',
        backgroundColor: '#6fbf34',
        marginTop: 3,
        '&:hover': {
            backgroundColor: '#6fbf34'
        }
    },
    infoGrid: {
        paddingTop: 5,
        paddingBottom: 8,
        paddingLeft: '1.5%',
        paddingRight: '1.5%'
    },
    infoHeader: {
        fontWeight: 'bold',
        color: '#055d36'
    },
    infoLearnMoreButton: {
        textTransform: 'none',
        marginTop: 2
    },
    freightUserGuideButton: {
        textTransform: 'none',
        marginTop: 2,
        backgroundColor: '#6fbf34',
        '&:hover': {
            backgroundColor: '#6fbf34'
        }
    }
};

function HomePage() {
    const [open, setOpen] = useState(true);
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const userServiceAreas = useSelector(state => state.serviceAreas.userServiceAreas);
    const availableLoads = useSelector(state => state.load.availableLoads);
    const openQuotes = useSelector(state => state.quote.openQuotes);
    const myAccountDetails = useSelector(state => state.myAccount.myAccountDetails);

	useEffect(() => { 
		document.title = "DFQ Vendor - Home"; 
        dispatch(serviceAreasActions.getUserServiceAreas(user.UserId));
        dispatch(loadActions.getAvailableLoads(user.UserId));
        dispatch(quoteActions.getOpenQuotes(user.UserId));
        dispatch(userActions.getMyAccountDetails(user.UserId));
	}, []);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Grid container spacing={2}>
                    {/* First Outer Column */}
                    <Grid item xs={12} sm={8}>
                        {/* First Row */}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <Typography variant="h6">Welcome Back,</Typography>
                                <Typography variant="h4" sx={{fontWeight: 'bold'}}>{user.FirstName} {user.LastName}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Link to='/loads/available'> 
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: '#2D54DA', color: 'white', textTransform: 'capitalize', marginTop: 30, float: 'right', paddingLeft: 30, paddingRight: 30, fontSize: 15, borderRadius: '10px' }}
                                        startIcon={<LocalShipping style={{fontSize: 25}}/>}
                                    >
                                        View Loads
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                        {/* Second Row */}
                        <Grid item xs={12} sx={{marginTop: 2}}>
                            <Box borderRadius={4} bgcolor="#33D3F6" p={4}>
                                <Grid container>
                                    <Grid item sm={6}>
                                        <Typography variant="h4" style={{ color: 'white', marginTop: 20 }}>DFQ's Online Freight Quote Vendor System</Typography>
                                        <Typography style={{ color: 'white', marginTop: 20, marginBottom: 20 }}>A trusted service to maximize your truck's potential</Typography>
                                        <Link to='/about/vendorsystem'> 
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: 'white', color: '#2D54DA', textTransform: 'capitalize', marginTop: '8px', paddingLeft: 30, paddingRight: 30, fontSize: 15, borderRadius: '10px', fontWeight: 'bold', marginBottom: 15 }}
                                            >
                                                Learn more
                                            </Button>
                                        </Link>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <img src={stockTruck} height={250} alt="Truck" style={{borderRadius: '10px'}} />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        {/* Third Row */}
                        <Grid container spacing={2} style={{marginTop: 3}}>
                            <Grid item sm={6} xs={12}>
                                <Box borderRadius={4} bgcolor="white" p={4} style={{height: '100%'}}>
                                    <Stack alignItems="center" direction="row" gap={1}>
                                        <SquareIcon style={{color: '#2D54DA', fontSize: 30}}/>
                                        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: 20}}>Available Loads</Typography>
                                    </Stack>
                                    <Typography style={{marginTop: 10}}>This section shows all the XYNYTH Manufacturing loads currently available to quote on. Enter this section to provide a quote for any or all available loads. All the information for each available load can be found here.</Typography>
                                    <Link to='/about/availableloads'>                                        
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'white', color: '#2D54DA', textTransform: 'capitalize', marginTop: 20, paddingLeft: 30, paddingRight: 30, fontSize: 15, borderRadius: '10px', fontWeight: 'bold' }}
                                        >
                                            Learn more
                                        </Button>
                                    </Link>
                                </Box>
                            </Grid>
                            <Grid item sm={6} xs={12}>
                                <Box borderRadius={4} bgcolor="white" p={4} style={{height: '100%'}}>
                                    <Stack alignItems="center" direction="row" gap={1}>
                                        <SquareIcon style={{color: '#2D54DA', fontSize: 30}}/>
                                        <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: 20}}>Open Quotes</Typography>
                                    </Stack>
                                    <Typography style={{marginTop: 10}}>This section shows the quotes a vendor has placed for all current, available loads.</Typography>
                                    <Link to='/about/openquotes'>
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'white', color: '#2D54DA', textTransform: 'capitalize', marginTop: 20, paddingLeft: 30, paddingRight: 30, fontSize: 15, borderRadius: '10px', fontWeight: 'bold' }}
                                        >
                                            Learn more
                                        </Button>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* Fourth Row */}
                        <Grid item xs={12} sx={{marginTop: 3}}>
                            <Box borderRadius={4} bgcolor="white" p={4}>
                                <Stack alignItems="center" direction="row" gap={1}>
                                    <SquareIcon style={{color: '#2D54DA', fontSize: 30}}/>
                                    <Typography variant="body1" sx={{fontWeight: 'bold', fontSize: 20}}>Service Areas</Typography>
                                </Stack>
                                <Typography style={{marginTop: 10}}>This section allows freight vendors to specify which areas they offer freight services, by province and state. This ensures each vendor will receive email notifications when loads are available in their specified service areas. <span style={{color: 'red'}}>**IMPORTANT: If service areas are NOT specified, email notifications will not be sent out**.</span></Typography>
                                <Link to='/about/serviceareas'>
                                    <Button
                                        variant="contained"
                                        style={{ backgroundColor: 'white', color: '#2D54DA', textTransform: 'capitalize', marginTop: 20, paddingLeft: 30, paddingRight: 30, fontSize: 15, borderRadius: '10px', fontWeight: 'bold', marginBottom: 10 }}
                                    >
                                        Learn more
                                    </Button>
                                </Link>
                            </Box>
                        </Grid>
                    </Grid>

                    {/* Second Outer Column */}
                    <Grid item xs={12} sm={4}>
                        {/* First Row */}
                        <Grid container justifyContent="center">
                            <Grid item xs={12}>
                                <Stack alignItems="center" direction="row" gap={10}>
                                    <Link to='/serviceareas'> 
                                        <Button
                                            variant="contained"
                                            style={{ backgroundColor: 'white', color: '#2D54DA', textTransform: 'capitalize', marginTop: 30, paddingLeft: 30, paddingRight: 30, fontSize: 15, borderRadius: '10px', fontWeight: 'bold' }}
                                        >
                                            Update Service Areas
                                        </Button>
                                    </Link>
                                    <Badge color="primary" badgeContent={userServiceAreas.length} style={{marginTop: 30}}>
                                        <AddLocationAltIcon style={{fontSize: 30}}/>
                                    </Badge>
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* Second Row */}
                        <Grid item xs={12} sx={{marginTop: 3}}>
                            <Box borderRadius={4} bgcolor="white" p={4} style={{maxHeight: '34vh', overflowY: 'auto'}}>
                                <Stack alignItems="center" direction="row" gap={1}>
                                    <SquareIcon style={{color: '#2D54DA', fontSize: 30}}/>
                                    <Typography variant="h6" sx={{fontWeight: 'bold'}}>Your Service Areas</Typography>
                                </Stack>
                                <Box style={{marginTop: 20}}>
                                    {userServiceAreas.length > 0 ?
                                            userServiceAreas.map((item, index) => (
                                                <Stack alignItems="center" direction="row" gap={1} key={index}>
                                                    {item.Country === 'Canada' || item.ProvinceState === 'CANADA' ? <img src={canadaFlag} height={30} alt='canada'/> : <img src={usaFlag} height={30} alt='usa'/>}
                                                    <Typography variant="h7" sx={{fontWeight: 'bold'}}>{item.Name}</Typography>
                                                </Stack>
                                            ))
                                        :
                                            <Alert severity="error">You have <span style={{fontWeight: 'bold'}}>ZERO</span> service areas set! Please update your service areas <span style={{fontWeight: 'bold'}}>ASAP</span>, as you will not receive loads to quote on without this setting</Alert>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        {/* Third Row */}
                        <Grid item xs={12} sx={{marginTop: 3}}>
                            <Box borderRadius={4} bgcolor="white" p={4}>
                                <Stack alignItems="center" direction="row" gap={1}>
                                    <Avatar sx={{bgcolor: '#2D54DA'}}>{availableLoads.length}</Avatar>
                                    <Typography variant="h6" sx={{fontWeight: 'bold'}}>Loads You Can Quote On</Typography>
                                </Stack>
                                {availableLoads.length > 0 ?
                                    <Typography sx={{marginTop: 3}}>There are loads available in your service area that you can quote on! Send a quote before the deadline is reached!</Typography>
                                :
                                    <Typography sx={{marginTop: 3}}>There are no loads that you can currently quote on</Typography>
                                }
                            </Box>
                        </Grid>
                        {/* Fourth Row */}
                        <Grid item xs={12} sx={{marginTop: 3}}>
                            <Box borderRadius={4} bgcolor="white" p={4}>
                                <Stack alignItems="center" direction="row" gap={1}>
                                    <Avatar sx={{bgcolor: '#2D54DA'}}>{openQuotes.length}</Avatar>
                                    <Typography variant="h6" sx={{fontWeight: 'bold'}}>Open Quotes</Typography>
                                </Stack>
                                {openQuotes.length > 0 ?
                                    <Typography sx={{marginTop: 3}}>You have quotes that could still be awarded to you! It's never too late to edit the details to make sure you submit the most competitive offer</Typography>
                                :
                                    <Typography sx={{marginTop: 3}}>Send in some more quotes by clicking <Link to='/loads/available'>here</Link></Typography>
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {myAccountDetails && (userServiceAreas.length === 0 || myAccountDetails.ShipmentType === null || myAccountDetails.VendorClass === null) ?
                <Dialog onClose={handleClose} open={open}>
                    <DialogTitle sx={{textAlign: 'center'}}>Update Account Details</DialogTitle>
                    {userServiceAreas.length === 0 && <Alert severity="error" sx={{padding: 2, margin: 2, textAlign: 'center'}}>You have not selected any service areas, please update them by clicking <Link to='/serviceareas'>here</Link></Alert>}
                    {myAccountDetails.ShipmentType === null && <Alert severity="error" sx={{padding: 2, margin: 2, textAlign: 'center'}}>You have not selected a shipment type, please update it by clicking <Link to='/myaccount'>here</Link></Alert>}
                    {myAccountDetails.VendorClass === null && <Alert severity="error" sx={{padding: 2, margin: 2, textAlign: 'center'}}>You have not selected a carrier type, please update it by clicking <Link to='/myaccount'>here</Link></Alert>}
                </Dialog>
            : null}
            {/* <Footer /> */}
        </Box>
    );
}

export { HomePage };