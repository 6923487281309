import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { loadActions, quoteActions } from '../../_actions';
import { numberWithCommas } from '../../_helpers';
import { addCapSpace } from '../../_helpers';
import { formatShipmentType } from '../../_helpers';
import NumericFormatCustom from '../../components/NumericFormatCustom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { FormControl } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import backgroundImage from '../../_resources/home_page_background.jpg';
import NavMenu from '../../components/NavMenu';
import Footer from '../../components/Footer';

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    gridStyle: {
        border: '1px solid #51c0de',
        paddingTop: 2,
        paddingBottom: 3,
    },
    locationListStyle: {
        paddingLeft: 1,
        paddingTop: 1
    },
    rightGridStyle: {
        border: '1px solid #51c0de',
        paddingRight: 1,
    },
    formInput: {
        width: '100%'
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    datePickerStyle: {
        marginTop: 2,
    },
    submitButton: {
        textTransform: 'none',
        fontWeight: 'bold',
        marginTop: 2,
        marginLeft: '40%',
    },
    circularProgressStyle: {
        marginLeft: '45%', 
        marginTop: 2
    },
    confirmationTextStyle: {
        marginLeft: 1,
        marginTop: 2,
        fontSize: 20
    },
    editQuoteTextStyle: {
        marginLeft: 1,
        fontSize: 20
    }
};

const validationSchema = Yup.object().shape({
    bestBasePrice: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    fuelSurcharge: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    totalDropFee: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
    currency: Yup.string()
        .required('Currency is required'),
    dateEquipmentAvailable: Yup.date()
        .required('Date is required')
        .typeError('Date is required'),
    equipmentType: Yup.string()
        .required('Equipment type is required'),
    trailerType: Yup.string()
        .required('Trailer type is required'),
    transitDays: Yup.number()
        .test(
            "is-positive",
            "Please enter a positive number",
            (value) => value >= 0
        )
        .typeError('Please enter a number'),
});

function LoadDetailsPage() {
    let { loadId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.load.loading);
    const loadDetails = useSelector(state => state.load.loadDetails);
    const sendingQuote = useSelector(state => state.quote.loading);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: { 'bestBasePrice': 0, 'fuelSurcharge': 0, 'totalDropFee': 0 }
    });

    const bestBasePrice = useWatch({control, name:'bestBasePrice'});
    const fuelSurcharge = useWatch({control, name:'fuelSurcharge'});
    const totalDropFee = useWatch({control, name:'totalDropFee'});

    useEffect(() => { 
		document.title = "DFQ Vendor - Load Details";
        dispatch(loadActions.getLoadDetails(loadId));
	}, []);

    const onSubmit = data => {
        data.loadId = loadId;
        data.userId = user.UserId;
        data.firstName = user.FirstName;
        data.lastName = user.LastName;
        data.totalQuote = Number(bestBasePrice) + Number(fuelSurcharge) + Number(totalDropFee);
        data.loadDetails = loadDetails;
        if(!data.yourComments){
            data.yourComments = " ";
        }
        dispatch(quoteActions.sendQuote(data));
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>Quote on this Load</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        {!loading && loadDetails ?
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={4}>
                                <Grid container spacing={1} sx={classes.gridStyle}>
                                    <Grid item xs={12} sm={6}>
                                        Load ID:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {loadDetails.LoadId}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Quote By:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {dayjs(loadDetails.QuoteBy).format('LLL')}
                                    </Grid>
                                    {loadDetails.PickupList.map((pickup, index) =>{
                                        return(
                                            <Grid container key={pickup} spacing={1} sx={classes.locationListStyle}>
                                                <Grid item xs={12} sm={6}>
                                                    Pickup #{index + 1}:
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {pickup}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    {loadDetails.DeliveryList.map((delivery, index) =>{
                                        return(
                                            <Grid container key={delivery} spacing={1} sx={classes.locationListStyle}>
                                                <Grid item xs={12} sm={6}>
                                                    Delivery #{index + 1}:
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    {delivery}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item xs={12} sm={6}>
                                        Crossing Border:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {loadDetails.CrossingBorder ? 'Yes' : 'No'}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Shipment Type:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {formatShipmentType(loadDetails.ShipmentType)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Total Pallets:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {numberWithCommas(loadDetails.TotalPallets)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Total Weight:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {numberWithCommas(loadDetails.TotalWeight)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Equipment Requested:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {addCapSpace(loadDetails.EquipmentRequested)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Trailer Requested:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {addCapSpace(loadDetails.TrailerRequested)}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        First Pickup Date:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {dayjs(loadDetails.FirstPickupDate).format('MMM DD, YYYY')}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Last Delivery Date:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {dayjs(loadDetails.LastDeliveryDate).format('MMM DD, YYYY')}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        Quoting Comments:
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {loadDetails.QuotingComments}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={classes.rightGridStyle}>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="bestBasePrice"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Your Best Base Price" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        error={errors.bestBasePrice ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.bestBasePrice?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="fuelSurcharge"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Total Surcharge(Fuel + Carbon Tax)" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        error={errors.fuelSurcharge ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.fuelSurcharge?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="totalDropFee"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Total Extra Fees (Drop Fees)" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        error={errors.totalDropFee ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.totalDropFee?.message}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="totalQuote"
                                                control={control}
                                                render={({ field }) => 
                                                    <TextField 
                                                        disabled
                                                        label="Total Quote" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        error={errors.totalQuote ? true : false}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start" sx={{paddingTop: .2, marginRight: .2}}>$</InputAdornment>,
                                                            inputComponent: NumericFormatCustom,
                                                        }}
                                                        value={Number(bestBasePrice) + Number(fuelSurcharge) + Number(totalDropFee)}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.totalQuote?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="currency"
                                                control={control}
                                                defaultValue="CAD"
                                                render={({ field }) => 
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="currencyLabel" sx={{marginTop: 2}}>Currency</InputLabel>
                                                        <Select labelId="currencyLabel" id="currency" sx={classes.selectInput} {...field} label="Currency">
                                                            <MenuItem value="CAD">CAD</MenuItem>
                                                            <MenuItem value="USD">USD</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.currency?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={4}>
                                            <Controller
                                                name="dateEquipmentAvailable"
                                                control={control}
                                                defaultValue={null}
                                                render={({ field }) => 
                                                    <FormControl fullWidth sx={classes.datePickerStyle}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DatePicker
                                                                slotProps={{
                                                                    textField: {
                                                                        error: false,
                                                                        size: 'small'
                                                                    },
                                                                }}
                                                                id="dateEquipmentAvailable"
                                                                {...field}
                                                                error={errors.dateEquipmentAvailable ? true : false}
                                                                label="Date Equipment Available"
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.dateEquipmentAvailable?.message}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="equipmentType"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => 
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="equipmentTypeLabel" sx={{marginTop: 2}}>Equipment Type</InputLabel>
                                                        <Select labelId="equipmentTypeLabel" id="equipmentType" sx={classes.selectInput} {...field} label="Equipment Type">
                                                            <MenuItem value="dryVan">Dry Van</MenuItem>
                                                            <MenuItem value="flatBed">Flat Bed</MenuItem>
                                                            <MenuItem value="pupTruck">Pup Truck</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.equipmentType?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="trailerType"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => 
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="trailerTypeLabel" sx={{marginTop: 2}}>Trailer Type</InputLabel>
                                                        <Select labelId="trailerTypeLabel" id="trailerType" sx={classes.selectInput} {...field} label="Trailer Type">
                                                            <MenuItem value="48Or 53Tandem">Tandem - 48' OR 53'</MenuItem>
                                                            <MenuItem value="48Tandem">Tandem - 48'</MenuItem>
                                                            <MenuItem value="53Tandem">Tandem - 53'</MenuItem>
                                                            <MenuItem value="superB">Super B</MenuItem>
                                                            <MenuItem value="48Tridem">Tridem - 48'</MenuItem>
                                                            <MenuItem value="53Tridem">Tridem - 53'</MenuItem>
                                                            <MenuItem value="48Or 53Tridem">Tridem - 48' OR 53'</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.trailerType?.message}
                                            </Typography>
                                        </Grid>
                                        <Grid item  xs={12} sm={4}>
                                            <Controller
                                                name="transitDays"
                                                control={control}
                                                defaultValue=""
                                                render={({ field }) => 
                                                    <TextField
                                                        label="Your Transit Days" 
                                                        variant="outlined" 
                                                        margin="normal" 
                                                        size="small"
                                                        type="number"
                                                        sx={classes.formInput} 
                                                        {...field} 
                                                        error={errors.transitDays ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.transitDays?.message}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Controller
                                                name="yourComments"
                                                control={control}
                                                defaultValue={loadDetails ? loadDetails.GeneralComments : ""}
                                                render={({ field }) => 
                                                    <TextField 
                                                        label="Your Comments (Optional)" 
                                                        variant="outlined" 
                                                        multiline
                                                        rows={3}
                                                        margin="normal" 
                                                        sx={classes.formInput}
                                                        {...field} 
                                                        error={errors.yourComments ? true : false}
                                                    />
                                                }
                                            />
                                            <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                {errors.yourComments?.message}
                                            </Typography>
                                        </Grid>
                                        
                                    </Grid>
                                    {!sendingQuote ?
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={classes.submitButton}
                                    > 
                                        Submit
                                    </Button>
                                    : <CircularProgress sx={classes.circularProgressStyle}/>}
                                    <Typography sx={classes.confirmationTextStyle}>Confirmation email will be sent to: <span style={{fontWeight: 'bold'}}>{user.UserId}</span></Typography>
                                    <Typography sx={classes.editQuoteTextStyle}>After submission, this quote can be edited from the 'Open Quotes' tab</Typography>
                                </form>
                            </Grid>
                        </Grid>
                        : <LinearProgress />}
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { LoadDetailsPage };