import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serviceAreasActions } from '../../_actions';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import backgroundImage from '../../_resources/home_page_background.jpg';
import NavMenu from '../../components/NavMenu';
import Footer from '../../components/Footer';

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    pageSubHeader: {
        marginTop: 2
    },
    stepTitle: {
        color: '#26B9F5',
        marginBottom: 1,
        fontWeight: 'bold'
    },
    stepSubTitle: {
        marginBottom: 4
    },
    submitButton: {
        textTransform: 'none',
        backgroundColor: '#2D54DA',
        '&:hover': {
            backgroundColor: '#2D54DA'
        },
    }
};

function ServiceAreasPage() {
    // Next 3 for transfer list
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.serviceAreas.loading);
    const restOfServiceAreas = useSelector(state => state.serviceAreas.restOfServiceAreas);
    const userServiceAreas = useSelector(state => state.serviceAreas.userServiceAreas);

    // Next 8 for transfer list
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
    
        setChecked(newChecked);
    };
    
    const numberOfChecked = (items) => intersection(checked, items).length;
    
    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
        
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };
    
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (title, items) => (
        <Card>
          <CardHeader
            sx={{ px: 2, py: 1 }}
            avatar={
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={
                  numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                  'aria-label': 'all items selected',
                }}
                name='titleCheckbox'
              />
            }
            titleTypographyProps={{variant:'h5' }}
            title={title}
            subheaderTypographyProps={{variant:'subheading' }}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
          />
          <Divider />
          <List
            sx={{
              width: 300,
              height: 500,
              bgcolor: 'background.paper',
              overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
          >
            {items.map((value) => {
              const labelId = `transfer-list-all-item-${value.ProvinceState}-label`;
    
              return (
                <ListItemButton
                  key={value.ProvinceState}
                  role="listitem"
                  onClick={handleToggle(value)}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        'aria-labelledby': labelId,
                      }}
                      name='serviceAreasCheckbox'
                    />
                  </ListItemIcon>
                  <ListItemText id={labelId} primary={value.ProvinceState + ' - ' + value.Name} />
                </ListItemButton>
              );
            })}
          </List>
        </Card>
    );

    useEffect(() => { 
		document.title = "DFQ Vendor - Service Areas";
        dispatch(serviceAreasActions.getUserServiceAreas(user.UserId));
	}, []);

    useEffect(() => { 
        if(restOfServiceAreas.length > 0 || userServiceAreas.length > 0){
            setLeft(restOfServiceAreas);
            setRight(userServiceAreas);
        }
	}, [restOfServiceAreas, userServiceAreas]);

    const submitServiceAreas = () => {
        console.log(user.UserId);
        console.log(right);
        var serviceAreaCodes = right.map(s => s.ProvinceState);
        console.log(serviceAreaCodes)
        dispatch(serviceAreasActions.updateServiceAreas(serviceAreaCodes, user.UserId));
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>Service Areas</Typography>
                        <Typography sx={classes.pageSubHeader}>Follow this easy 3-Step process to let us know which Provinces and States your company offers freight services.</Typography>
                        {!loading && (left.length > 0 || right.length > 0) ?
                        <>
                        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{marginTop: 1, marginBottom: 3}}>
                            <Grid item>
                                <Typography variant='h4' sx={classes.stepTitle}>Step# 1</Typography>
                                <Typography sx={classes.stepSubTitle}><span style={{fontWeight: 'bold'}}>CHOOSE</span> the Provinces and States you service</Typography>
                                {customList('Province / State', left)}
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        sx={{ my: 0.5, textTransform: 'none' }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedRight}
                                        disabled={leftChecked.length === 0}
                                        aria-label="move selected right"
                                    >
                                        Add &gt;
                                    </Button>
                                    <Button
                                        sx={{ my: 0.5, textTransform: 'none' }}
                                        variant="outlined"
                                        size="small"
                                        onClick={handleCheckedLeft}
                                        disabled={rightChecked.length === 0}
                                        aria-label="move selected left"
                                    >
                                        Remove &lt;
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant='h4' sx={classes.stepTitle}>Step# 2</Typography>
                                <Typography sx={classes.stepSubTitle}><span style={{fontWeight: 'bold'}}>Add</span> to Your Service Areas</Typography>
                                {customList('Your Service Areas', right)}
                            </Grid>
                            <Grid item>
                                <Container sx={{height: 680}}>
                                    <Typography variant='h4' sx={classes.stepTitle}>Step# 3</Typography>
                                    <Typography sx={classes.stepSubTitle}><span style={{fontWeight: 'bold'}}>Confirm</span></Typography>
                                    <Typography variant='h5' sx={{marginBottom: 3}}>Confirmation</Typography>
                                    <TextField
                                        label="Confirmation will be sent to:" 
                                        variant="outlined" 
                                        margin="normal" 
                                        size="small"
                                        disabled
                                        name="userId"
                                        value={user.UserId}
                                    />
                                    <br />
                                    <Typography sx={{marginTop: 2}}>Click submit to finalize entries</Typography>
                                    <Button variant='contained' sx={classes.submitButton} onClick={() => submitServiceAreas()}>Submit</Button>
                                </Container>
                            </Grid>
                        </Grid>
                        </>
                        : <LinearProgress />}
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { ServiceAreasPage };