export const compareUtil = {
    getComparator,
    stableSort
};

function descendingComparator(a, b, orderBy) {
    if(orderBy === 'RequestedShipDate' || orderBy === 'QuoteBy' || 'Submitted' || 'AvailableDate'){
        return (new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf());
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};