export const userConstants = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILURE: 'LOGIN_FAILURE',

    USER_LOGOUT: 'USER_LOGOUT',

    SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
    SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',

    GET_MY_ACCOUNT_DETAILS_REQUEST: 'GET_MY_ACCOUNT_DETAILS_REQUEST',
    GET_MY_ACCOUNT_DETAILS_SUCCESS: 'GET_MY_ACCOUNT_DETAILS_SUCCESS',
    GET_MY_ACCOUNT_DETAILS_FAILURE: 'GET_MY_ACCOUNT_DETAILS_FAILURE',

    UPDATE_MY_ACCOUNT_DETAILS_REQUEST: 'UPDATE_MY_ACCOUNT_DETAILS_REQUEST',
    UPDATE_MY_ACCOUNT_DETAILS_SUCCESS: 'UPDATE_MY_ACCOUNT_DETAILS_SUCCESS',
    UPDATE_MY_ACCOUNT_DETAILS_FAILURE: 'UPDATE_MY_ACCOUNT_DETAILS_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

    SEND_RESET_PASSWORD_LINK_REQUEST: 'SEND_RESET_PASSWORD_LINK_REQUEST',
    SEND_RESET_PASSWORD_LINK_SUCCESS: 'SEND_RESET_PASSWORD_LINK_SUCCESS',
    SEND_RESET_PASSWORD_LINK_FAILURE: 'SEND_RESET_PASSWORD_LINK_FAILURE',

    VALIDATE_RESET_PASSWORD_KEY_REQUEST: 'VALIDATE_RESET_PASSWORD_KEY_REQUEST',
    VALIDATE_RESET_PASSWORD_KEY_SUCCESS: 'VALIDATE_RESET_PASSWORD_KEY_SUCCESS',
    VALIDATE_RESET_PASSWORD_KEY_FAILURE: 'VALIDATE_RESET_PASSWORD_KEY_FAILURE',

    RESET_PASSWORD_WITH_KEY_REQUEST: 'RESET_PASSWORD_WITH_KEY_REQUEST',
    RESET_PASSWORD_WITH_KEY_SUCCESS: 'RESET_PASSWORD_WITH_KEY_SUCCESS',
    RESET_PASSWORD_WITH_KEY_FAILURE: 'RESET_PASSWORD_WITH_KEY_FAILURE',

    DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
    DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
    DELETE_ACCOUNT_FAILURE: 'DELETE_ACCOUNT_FAILURE',
};