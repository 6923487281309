import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import backgroundImage from '../../../_resources/home_page_background.jpg';
import warehouseImage from '../../../_resources/about_available_loads_page_warehouse.jpg';
import NavMenu from '../../../components/NavMenu';
import Footer from '../../../components/Footer';

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    warehouseImageStyle: {
        float: 'right',
        marginLeft: 2
    },
    listStyle: {
        listStyleType: 'none',
        fontSize: 20,
        borderLeft: '4px solid',
        borderColor: '#f0ecec',
        paddingLeft: 22
    },
    toQuoteOntextStyle: {
        marginTop: 2,
        marginBottom: 3
    },
    linkStyle: {
        textDecoration: 'none',
        color: '#428bca'
    },
};

function AboutAvailableLoadsPage() {

	useEffect(() => { 
		document.title = "DFQ Vendor - About Available Loads"; 
	}, []);

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters sx={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>AVAILABLE LOADS</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        <Container>
                            <img src={warehouseImage} alt='logo' style={classes.warehouseImageStyle}/>
                            <Typography>This section provides a list view of all available loads along with all the information pertaining to the shipment.  This information includes:</Typography>
                            <ul style={classes.listStyle}>
                                <li>Load ID</li>
                                <li>Lane (Ship From and Ship To Locations)</li>
                                <li>Quote Closing Time</li>
                                <li>Shipment Type (TL Road, Intermodal, LTL)</li>
                                <li>Total Pallet Count</li>
                                <li>Total Weight</li>
                                <li>Equipment Requested (48’ or 53’ Trailer, Tandem, Triaxle, Dry Van, Flat Bed)</li>
                                <li>Ship Date</li>
                            </ul>
                            <Typography>All available loads are open for quoting for a finite period of time, which can be anywhere from 2 to 24 hours.  Vendors are welcome to submit numerous quotes during this time.  However, once this time period has passed, the load is no longer available to quote on.  </Typography>
                            <Typography sx={classes.toQuoteOntextStyle}><Link to='/loads/available' style={classes.linkStyle}>To quote on an available load</Link>, select the Load ID and a quote sheet will open up.  Please note, a number of fields are mandatory in order for the quote to be processed.</Typography>
                        </Container>
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { AboutAvailableLoadsPage };