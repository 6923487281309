import React, { useState, useEffect } from 'react';
import { Box, Link, Typography, List, ListItem, ListItemIcon, ListItemText, Button, IconButton, Drawer, useMediaQuery } from '@mui/material';
import { Home, LocalShipping, Receipt, History, Map, AccountCircle, HelpOutline, ExitToApp, Menu } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/system';

const NavContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#2D54DA',
  width: '80%',
  maxWidth: '300px',
  borderRadius: '10px',
  marginTop: theme.spacing(2), // Increase top margin for extra height
  marginBottom: theme.spacing(2), // Increase bottom margin for extra height
  marginLeft: theme.spacing(2),
  padding: theme.spacing(2),
  color: 'white',
  position: 'relative', // Position relative for absolute positioning of SupportBox
  height: 'calc(100vh - 20px)',
  [theme.breakpoints.up('sm')]: {
    width: '300px',
  },
}));

const SupportBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#3E65F2',
  borderRadius: '10px',
  padding: theme.spacing(2),
  textAlign: 'center',
  color: 'white',
  position: 'absolute',
  bottom: theme.spacing(2), // Position SupportBox at the bottom
  left: theme.spacing(2), // Align SupportBox to the left
  right: theme.spacing(2), // Align SupportBox to the right
}));

const NavMenu = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => { 
        if(window.location.pathname === '/loads/available'){
            setSelectedIndex(1);
        }else if(window.location.pathname === '/quotes/open'){
            setSelectedIndex(2);
        }else if(window.location.pathname === '/quotes/past'){
            setSelectedIndex(3);
        }else if(window.location.pathname === '/serviceareas'){
            setSelectedIndex(4);
        }else if(window.location.pathname === '/myaccount'){
            setSelectedIndex(5);
        }else if(window.location.pathname === '/help'){
            setSelectedIndex(6);
        }
    }, []);

    const menuItems = [
        { text: 'Home', icon: <Home />, link: '/home' },
        { text: 'Available Loads', icon: <LocalShipping />, link: '/loads/available' },
        { text: 'Open Quotes', icon: <Receipt />, link: '/quotes/open' },
        { text: 'Past Quotes', icon: <History />, link: '/quotes/past' },
        { text: 'Service Areas', icon: <Map />, link: '/serviceareas' },
        { text: 'My Account', icon: <AccountCircle />, link: '/myaccount' },
        { text: 'Help', icon: <HelpOutline />, link: '/help' },
        { text: 'Log Out', icon: <ExitToApp />, link: '/' },
    ];

    const handleListItemClick = (index) => {
        setSelectedIndex(index);
        if (isMobile) setOpen(false); // Close the drawer on mobile
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ marginLeft: .1, position: 'fixed', top: 0, left: 0, zIndex: 1300 }}
          >
            <Menu sx={{ color: '#2D54DA' }} />
          </IconButton>
          <Drawer
            anchor="left"
            open={open}
            onClose={toggleDrawer}
            sx={{
              '& .MuiDrawer-paper': {
                width: '80%',
                maxWidth: '300px',
                backgroundColor: '#2D54DA',
                color: 'white',
                borderRadius: '10px',
                paddingTop: theme.spacing(2),
              },
            }}
          >
            <Box display="flex" alignItems="center" style={{marginBottom: 40, marginTop: 30}}>
                {/* <img src="logo.png" alt="Company Logo" style={{ marginRight: 10 }} /> */}
                <LocalShipping style={{ color: '#1ECEF5', marginLeft: 20, marginRight: 10, fontSize: 75 }}/>
                <Typography variant="h4" color="white" style={{fontWeight: 'bold'}}>
                DFQ
                </Typography>
            </Box>
            <List>
              {menuItems.map((item, index) => (
                <Link key={item.text} href={item.link} underline="none">
                    <ListItem
                        button
                        key={item.text}
                        onClick={() => handleListItemClick(index)}
                        sx={{
                            borderRadius: '10px',
                            backgroundColor: selectedIndex === index ? 'white' : 'transparent',
                            color: selectedIndex === index ? '#2D54DA' : 'white',
                            '&:hover': {
                                backgroundColor: selectedIndex === index ? 'white' : 'rgba(255, 255, 255, 0.1)',
                            },
                            '& .MuiListItemIcon-root': {
                            color: selectedIndex === index ? '#2D54DA' : 'white',
                            },
                            transition: 'background-color 0.3s, color 0.3s',
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                </Link>
              ))}
            </List>
            <SupportBox>
              <Typography variant="body2" color="white" gutterBottom>
                Having any problems managing your dashboard? Please contact the customer support!
              </Typography>
              <Link href='/help' underline="none">
                <Button
                    variant="contained"
                    sx={{
                    backgroundColor: '#1ECEF5',
                    color: 'white',
                    borderRadius: '5px',
                    textTransform: 'capitalize',
                    '&:hover': {
                        backgroundColor: '#1ECEF5',
                        opacity: 0.8,
                    },
                    }}
                >
                    Contact Us
                </Button>
              </Link>
            </SupportBox>
          </Drawer>
        </>
      ) : (
        <NavContainer>
          <Box display="flex" alignItems="center" style={{marginBottom: 40, marginTop: 30}}>
            {/* <img src="logo.png" alt="Company Logo" style={{ marginRight: 10 }} /> */}
            <LocalShipping style={{ color: '#1ECEF5', marginLeft: 20, marginRight: 10, fontSize: 75 }}/>
            <Typography variant="h4" color="white" style={{fontWeight: 'bold'}}>
              DFQ
            </Typography>
          </Box>
          <List component="nav">
            {menuItems.map((item, index) => (
              <Link key={item.text} href={item.link} underline="none">
                <ListItem
                  button
                  onClick={() => handleListItemClick(index)}
                  sx={{
                    borderRadius: '10px',
                    backgroundColor: selectedIndex === index ? 'white' : 'transparent',
                    color: selectedIndex === index ? '#2D54DA' : 'white',
                    padding: (theme) => theme.spacing(1, 2),
                    margin: (theme) => theme.spacing(0.5, 0),
                    width: '95%',
                    '&:hover': {
                      backgroundColor: selectedIndex === index ? 'white' : 'rgba(255, 255, 255, 0.1)',
                    },
                    '& .MuiListItemIcon-root': {
                      color: selectedIndex === index ? '#2D54DA' : 'white',
                    },
                    transition: 'background-color 0.3s, color 0.3s',
                  }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              </Link>
            ))}
          </List>
          <SupportBox>
            <Typography variant="body2" color="white" gutterBottom>
              Having any problems managing your dashboard? Please contact the customer support!
            </Typography>
            <Link href='/help' underline="none">
                <Button
                    variant="contained"
                    sx={{
                        backgroundColor: '#1ECEF5',
                        color: 'white',
                        borderRadius: '5px',
                        textTransform: 'capitalize',
                        '&:hover': {
                        backgroundColor: '#1ECEF5',
                        opacity: 0.8,
                        },
                    }}
                >
                    Contact Us
                </Button>
            </Link>
          </SupportBox>
        </NavContainer>
      )}
    </>
  );
};

export default NavMenu;
