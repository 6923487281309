import { loadConstants } from '../_constants';
import { loadService } from '../_services';
import { alertActions } from './';


export const loadActions = {
    getAvailableLoads,
    getLoadDetails
};

function getAvailableLoads(userId) {
    return dispatch => {
        dispatch(request());
        loadService.getAvailableLoads(userId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        //dispatch(alertActions.success('Found available loads!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_AVAILABLE_LOADS_REQUEST } }
    function success(availableLoads) { return { type: loadConstants.GET_AVAILABLE_LOADS_SUCCESS, availableLoads } }
    function failure() { return { type: loadConstants.GET_AVAILABLE_LOADS_FAILURE } }
}

function getLoadDetails(loadId) {
    return dispatch => {
        dispatch(request());
        loadService.getLoadDetails(loadId)
            .then(
                async response => {
                    if(response.status === 200){
                        dispatch(success(response.data.Result));
                        dispatch(alertActions.success('Found load details!'));
                    }
                }
            )
            .catch(
                async error => {
                    if (error.response){
                        dispatch(failure());
                        dispatch(alertActions.error(JSON.stringify(error.response.data.Error.Message).slice(1, -1)));
                    }
                }
            );
    }

    function request() { return { type: loadConstants.GET_LOAD_DETAILS_REQUEST } }
    function success(loadDetails) { return { type: loadConstants.GET_LOAD_DETAILS_SUCCESS, loadDetails } }
    function failure() { return { type: loadConstants.GET_LOAD_DETAILS_FAILURE } }
}