import axiosInstance from '../_helpers/axiosInstance';

export const serviceAreasService = {
    getUserServiceAreas,
    updateServiceAreas
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function getUserServiceAreas(userId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/ServiceArea/user?UserId=${userId}`
    });
}

function updateServiceAreas(serviceAreas, userId){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/ServiceArea/update`,
        data: {
            ServiceAreas: serviceAreas,
            UserId: userId
        }
    });
}