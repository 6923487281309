import axiosInstance from '../_helpers/axiosInstance';

export const quoteService = {
    sendQuote,
    getOpenQuotes,
    getPastQuotes,
    getQuoteDetails,
    updateQuoteDetails
};

const API_URL = process.env.REACT_APP_API_ENDPOINT;

function sendQuote(data){
    return axiosInstance({
        method: 'POST',
        url:`${API_URL}/Quote/Send`,
        data: {
            LoadId: data.loadId,
            UserId: data.userId,
            BestPrice: data.bestBasePrice,
            TotalSurcharges: data.fuelSurcharge,
            ExtraFees: data.totalDropFee,
            TotalQuote: data.totalQuote,
            Currency: data.currency,
            DateEquipmentAvailable: data.dateEquipmentAvailable,
            EquipmentType: data.equipmentType,
            TrailerType: data.trailerType,
            TransitDays: data.transitDays,
            Comments: data.yourComments,
            FirstName: data.firstName,
            LastName: data.lastName,
            LoadDetails: data.loadDetails
        }
    });
}

function getOpenQuotes(userId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/Quote/Open?UserId=${userId}`
    });
}

function getPastQuotes(userId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/Quote/Past?UserId=${userId}`
    });
}

function getQuoteDetails(quoteId){
    return axiosInstance({
        method: 'GET',
        url:`${API_URL}/Quote/Details?QuoteId=${quoteId}`
    });
}

function updateQuoteDetails(data){
    return axiosInstance({
        method: 'PUT',
        url:`${API_URL}/Quote/Update`,
        data: {
            QuoteId: data.quoteId,
            BestPrice: data.bestBasePrice,
            TotalSurcharges: data.fuelSurcharge,
            ExtraFees: data.totalDropFee,
            TotalQuote: data.totalQuote,
            Currency: data.currency,
            DateEquipmentAvailable: data.dateEquipmentAvailable,
            EquipmentType: data.equipmentType,
            TrailerType: data.trailerType,
            TransitDays: data.transitDays,
            Comments: data.yourComments,
        }
    });
}