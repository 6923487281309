import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';

function AccountCreatedPage() {
    const dispatch = useDispatch();

    useEffect(() => {
        document.title = "DFQ Vendor - Account Created"; 
    }, []);


    return (
        <>
        <CssBaseline />
        <Container sx={{textAlign: 'center', padding: 10}}>
            <Typography variant="h3">Thank you for signing up!</Typography>
            <Typography variant="h5" sx={{paddingTop: 5}}>A confirmation link has been sent to your email.</Typography>
            <Typography variant="h5">Click <Link to='/' style={{textDecoration: 'none', paddingTop: 6}}>here</Link> to go to the home page</Typography>
        </Container>
        </>
    );
}

export { AccountCreatedPage };