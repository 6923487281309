export const quoteConstants = {
    SEND_QUOTE_REQUEST: 'SEND_QUOTE_REQUEST',
    SEND_QUOTE_SUCCESS: 'SEND_QUOTE_SUCCESS',
    SEND_QUOTE_FAILURE: 'SEND_QUOTE_FAILURE',

    GET_OPEN_QUOTES_REQUEST: 'GET_OPEN_QUOTES_REQUEST',
    GET_OPEN_QUOTES_SUCCESS: 'GET_OPEN_QUOTES_SUCCESS',
    GET_OPEN_QUOTES_FAILURE: 'GET_OPEN_QUOTES_FAILURE',

    GET_PAST_QUOTES_REQUEST: 'GET_PAST_QUOTES_REQUEST',
    GET_PAST_QUOTES_SUCCESS: 'GET_PAST_QUOTES_SUCCESS',
    GET_PAST_QUOTES_FAILURE: 'GET_PAST_QUOTES_FAILURE',

    GET_QUOTE_DETAILS_REQUEST: 'GET_QUOTE_DETAILS_REQUEST',
    GET_QUOTE_DETAILS_SUCCESS: 'GET_QUOTE_DETAILS_SUCCESS',
    GET_QUOTE_DETAILS_FAILURE: 'GET_QUOTE_DETAILS_FAILURE',

    UPDATE_QUOTE_DETAILS_REQUEST: 'UPDATE_QUOTE_DETAILS_REQUEST',
    UPDATE_QUOTE_DETAILS_SUCCESS: 'UPDATE_QUOTE_DETAILS_SUCCESS',
    UPDATE_QUOTE_DETAILS_FAILURE: 'UPDATE_QUOTE_DETAILS_FAILURE',
};