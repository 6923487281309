import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import backgroundImage from '../../_resources/home_page_background.jpg';
import vendorManual from '../../_resources/Vendor_Manual.pdf';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2,
        marginBottom: 5
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#055d36'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
};

function VendorManualPage() {

	useEffect(() => { 
		document.title = "DFQ Vendor - Vendor Manual"; 
	}, []);

    return (
        <>
        <CssBaseline />
        <Header />
        <Container maxWidth={false} disableGutters sx={classes.pageContainer}>
            <Container sx={classes.contentContainer}>
                <Paper elevation={3} sx={classes.paperStyle}>
                    <Typography variant='h5' sx={classes.pageHeader}>Vendor Manual</Typography>
                    <Divider sx={classes.dividerStyle}/>
                    <div style={{overflowX: 'auto'}}>
                        <iframe src={vendorManual} style={{width: '100%', height: '100vh'}}>This browser does not support PDFs</iframe>
                    </div>
                </Paper>
            </Container>
        </Container>
        <Footer />
        </>
    );
}

export { VendorManualPage };