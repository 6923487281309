import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../_actions';
import { provinceList } from '../../_helpers';
import { stateList } from '../../_helpers';
import { phoneNumberAutoFormat } from '../../_helpers';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import backgroundImage from '../../_resources/home_page_background.jpg';
import NavMenu from '../../components/NavMenu';
import Footer from '../../components/Footer';

const validationSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('First name is required'),
    lastName: Yup.string()
        .required('Last name is required'),
    email: Yup.string()
        .required('Email address is required')
		.matches(/.+@.+\..+/, 'Please enter a valid email'),
    phoneNumber: Yup.string()
        .required('Phone number is required'),
    companyName: Yup.string()
        .required('Company name is required'),
    companyCountry: Yup.string()
        .required('Country is required'),
	companyProvinceState: Yup.string()
        .required('Province/State is required'),
    companyCity: Yup.string()
        .required('City is required'),
    companyAddress: Yup.string()
        .required('Company address is required'),
	companyPostalZip: Yup.string()
        .required('Postal/zip code is required'),
    currency: Yup.string()
		.required('Currency is required'),
    shipmentType: Yup.string()
		.required('Shipment type is required'),
    carrierType: Yup.string()
		.required('Carrier type is required'),
});

const validationSchema2 = Yup.object().shape({
    password: Yup.string()
        .required('Password is required')
		.matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/, "Password must be at least 8 characters and must contain an upper case letter, lower case letter and a number")
        .max(100, 'Password must not exceed 100 characters'),
    confirmPassword: Yup.string()
        .required('Confirm password is required')
		.oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
});


const classes = {
    pageContainer: {
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: '100% auto',
        overflow: 'hidden',
        minHeight: '75vh'
    },
    contentContainer: {

    },
    paperStyle: {
        padding: 2,
        marginTop: 2,
    },
    pageHeader: {
        fontWeight: 'bold',
        color: '#2D54DA'
    },
    dividerStyle: {
        marginTop: 3,
        marginBottom: 3
    },
    formHeader: {
        backgroundColor: '#2D54DA',
        color: 'white',
        padding: 1
    },
    formInput: {
        width: '100%',
    },
    selectInput: {
		width: '100%',
		marginTop: 2,
	},
    updateButton: {
        left: '45%',
		width: '10%',
		marginTop: 3,
		backgroundColor: '#26B9F5',
        '&:hover': {
            backgroundColor: '#26B9F5'
        },
        fontSize: 15,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
	},
    updatePasswordButton: {
        left: '5%',
		width: '10%',
		marginTop: 1,
		backgroundColor: '#26B9F5',
        '&:hover': {
            backgroundColor: '#26B9F5'
        },
        fontSize: 15,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
    },
    deleteAccountButton: {
        left: '5%',
		width: '10%',
		marginTop: 1,
		backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'red'
        },
        fontSize: 15,
		textTransform: 'none',
		fontWeight: 'bold',
		fontSize: 15,
    }
};

function MyAccountPage() {
    const [selectedCountry, setSelectedCountry] = useState('Canada');
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);
    const loading = useSelector(state => state.myAccount.loading);
    const myAccountDetails = useSelector(state => state.myAccount.myAccountDetails);

    const { control, handleSubmit, register, formState: { errors, submitCount }, getValues, setValue, trigger} = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { control: control2, handleSubmit: handleSubmit2, register: register2, formState: { errors: errors2, submitCount: submitCount2 }, getValues: getValues2, setValue: setValue2, trigger: trigger2} = useForm({
        resolver: yupResolver(validationSchema2)
    });

    useEffect(() => { 
		document.title = "DFQ Vendor - My Account";
        dispatch(userActions.getMyAccountDetails(user.UserId));
	}, []);

	useEffect(() => { 
        if(selectedCountry){
            renderProvinceStateList();
        }
	}, [selectedCountry]);

    useEffect(() => { 
        if(myAccountDetails){
            setSelectedCountry(myAccountDetails.CompanyCountry);
        }
	}, [myAccountDetails]);

    const renderProvinceStateList = () => {
		if(selectedCountry === 'Canada'){
			return provinceList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}else if(selectedCountry === 'USA'){
			return stateList.map((state, keyIndex) => {
				return (<MenuItem key={keyIndex} value={ state.Name }>{ state.Name }</MenuItem>);
			});
		}
    };

    const onSubmit = data => {
        data.userId = user.UserId;
        dispatch(userActions.updateMyAccountDetails(data));
    };

    const onPhoneNumberChange = (input) => {
        let formattedNumber = phoneNumberAutoFormat(input.target.value);
        setValue(input.target.name, formattedNumber);
    };

    const changePassword = data => {
        data.userId = user.UserId;
        dispatch(userActions.changePassword(data));
    };

    const deleteAccount = () => {
        if(window.confirm("Are you sure you want to delete your account?")){
            dispatch(userActions.deleteAccount(user.UserId));
        }
    };

    return (
        <Box display="flex" style={{backgroundColor: '#F2F5FD'}}>
            <CssBaseline />
            <NavMenu />
            <Container maxWidth={false} disableGutters x={{ marginLeft: 2, marginTop: 2, marginRight: 2 }}>
                <Container sx={classes.contentContainer}>
                    <Paper elevation={3} sx={classes.paperStyle}>
                        <Typography variant='h5' sx={classes.pageHeader}>My Account</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        {!loading && myAccountDetails ?
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Container>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography sx={classes.formHeader}>Your Contact Information</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name="firstName"
                                                    control={control}
                                                    defaultValue={myAccountDetails.FirstName}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="First Name" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.firstName ? true : false}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.firstName?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name="lastName"
                                                    control={control}
                                                    defaultValue={myAccountDetails.LastName}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Last Name" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput}  
                                                            {...field} 
                                                            error={errors.lastName ? true : false}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.lastName?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    defaultValue={myAccountDetails.Email}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            disabled
                                                            label="Email Address" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.email ? true : false}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.email?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name="phoneNumber"
                                                    control={control}
                                                    defaultValue={myAccountDetails.PhoneNumber}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Phone Number" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput}  
                                                            {...field} 
                                                            error={errors.phoneNumber ? true : false}
                                                            onChange={(e) => {
                                                                onPhoneNumberChange(e);
                                                            }}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.phoneNumber?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography sx={classes.formHeader}>Company Information</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name="companyName"
                                                    control={control}
                                                    defaultValue={myAccountDetails.CompanyName}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            disabled
                                                            label="Company Name" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.companyName ? true : false}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.companyName?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name="companyCountry"
                                                    control={control}
                                                    defaultValue={myAccountDetails.CompanyCountry}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Country"
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            select
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.companyCountry ? true : false}
                                                            onChange={(e) => {
                                                                field.onChange(e); 
                                                                setSelectedCountry(e.target.value); 
                                                                setValue('companyProvinceState', '');
                                                                if (submitCount > 0)
                                                                    trigger('companyProvinceState');
                                                            }}
                                                        >
                                                            <MenuItem value="Canada">Canada</MenuItem>
                                                            <MenuItem value="USA">USA</MenuItem>
                                                        </TextField>
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.companyCountry?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <Controller
                                                    name="companyProvinceState"
                                                    control={control}
                                                    defaultValue={myAccountDetails.CompanyProvinceState}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Province/State"
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            select
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.companyProvinceState ? true : false}
                                                        >
                                                            {renderProvinceStateList()}
                                                        </TextField>
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.companyProvinceState?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item  xs={12} sm={6}>
                                                <Controller
                                                    name="companyCity"
                                                    control={control}
                                                    defaultValue={myAccountDetails.CompanyCity}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Company City" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.companyCity ? true : false}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.companyCity?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item  xs={12} sm={6}>
                                                <Controller
                                                    name="companyAddress"
                                                    control={control}
                                                    defaultValue={myAccountDetails.CompanyAddress}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Company Address" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.companyAddress ? true : false}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.companyAddress?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item  xs={12} sm={6}>
                                                <Controller
                                                    name="companyPostalZip"
                                                    control={control}
                                                    defaultValue={myAccountDetails.CompanyPostalZip}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Company Postal/Zip Code" 
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.companyPostalZip ? true : false}
                                                        />
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.companyPostalZip?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item  xs={12} sm={6}>
                                                <Controller
                                                    name="currency"
                                                    control={control}
                                                    defaultValue={myAccountDetails.Currency}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Currency"
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            select
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.currency ? true : false}
                                                        >
                                                            <MenuItem value="CAD">CAD</MenuItem>
                                                            <MenuItem value="USD">USD</MenuItem>
                                                        </TextField>
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.currency?.message}
                                                </Typography>
                                            </Grid>
                                            <Grid item  xs={12} sm={6}>
                                                <Controller
                                                    name="shipmentType"
                                                    control={control}
                                                    defaultValue={myAccountDetails.ShipmentType}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Shipment Type"
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            select
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.shipmentType ? true : false}
                                                        >
                                                            <MenuItem value="tlRoad">TL (Full Truckload)</MenuItem>
                                                            <MenuItem value="ltl">LTL (Less Than Truckload)</MenuItem>
                                                            <MenuItem value="both">Both (TL & LTL)</MenuItem>
                                                        </TextField>
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.shipmentType?.message}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item  xs={12} sm={6}>
                                                <Controller
                                                    name="carrierType"
                                                    control={control}
                                                    defaultValue={myAccountDetails.VendorClass}
                                                    render={({ field }) => 
                                                        <TextField 
                                                            label="Carrier Type"
                                                            variant="outlined" 
                                                            margin="normal" 
                                                            size="small"
                                                            select
                                                            sx={classes.formInput} 
                                                            {...field} 
                                                            error={errors.carrierType ? true : false}
                                                        >
                                                            <MenuItem value="assetBased">Asset Based</MenuItem>
                                                            <MenuItem value="broker">Broker</MenuItem>
                                                        </TextField>
                                                    }
                                                />
                                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                    {errors.carrierType?.message}
                                                </Typography>
                                            </Grid>
                                            {myAccountDetails.VendorClass === 'assetBased' ? 
                                                <Grid item  xs={12} sm={6}>
                                                    <Controller
                                                        name="numberOfTrucks"
                                                        control={control}
                                                        defaultValue={myAccountDetails.TrucksOwned ? myAccountDetails.TrucksOwned : 0}
                                                        render={({ field }) => 
                                                            <TextField 
                                                                label="Number of Trucks Owned" 
                                                                variant="outlined" 
                                                                margin="normal" 
                                                                size="small"
                                                                type="number"
                                                                sx={classes.formInput} 
                                                                {...field} 
                                                                error={errors.numberOfTrucks ? true : false}
                                                            />
                                                        }
                                                    />
                                                    <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                                        {errors.numberOfTrucks?.message}
                                                    </Typography>
                                                </Grid>
                                            : null}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {!loading ?
                                    <Button type="submit" variant="contained" sx={classes.updateButton}>Update</Button>
                                :<CircularProgress sx={{marginLeft: '48%', marginTop: 2}}/>}
                            </Container>
                        </form>
                        : <LinearProgress />}
                        <br />
                        <Typography variant='h5' sx={classes.pageHeader}>Change Password</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        {!loading ?
                        <form onSubmit={handleSubmit2(changePassword)}>
                            <Container>
                                <Controller
                                    name="password"
                                    control={control2}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <TextField 
                                            label="Password" 
                                            variant="outlined" 
                                            margin="normal" 
                                            size="small"
                                            type="password"
                                            {...field} 
                                            error={errors2.password ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors2.password?.message}
                                </Typography>
                                <Controller
                                    name="confirmPassword"
                                    control={control2}
                                    defaultValue=""
                                    render={({ field }) => 
                                        <TextField 
                                            label="Confirm Password" 
                                            variant="outlined" 
                                            margin="normal" 
                                            size="small"
                                            type="password"
                                            {...field} 
                                            error={errors2.confirmPassword ? true : false}
                                        />
                                    }
                                />
                                <Typography variant="inherit" color="error" style={{textAlign:'left'}}>
                                    {errors2.confirmPassword?.message}
                                </Typography>
                                <Button type="submit" variant="contained" sx={classes.updatePasswordButton}>Update</Button>
                                <Typography sx={{marginTop: 4}}>*Password must be at least 8 characters and must contain an upper case letter, lower case letter, and a number</Typography>
                                <Typography sx={{marginBottom: 2}}>*You will be prompted to login with your new password when it is changed</Typography>
                            </Container>
                        </form>
                        : <LinearProgress />}
                        <br />
                        <Typography variant='h5' sx={classes.pageHeader}>Delete Account</Typography>
                        <Divider sx={classes.dividerStyle}/>
                        <Typography>Click on the button below if you would like to delete your account</Typography>
                        {!loading ?
                            <Button onClick={() => deleteAccount()} variant="contained" sx={classes.deleteAccountButton}>Delete</Button>
                        : <LinearProgress />}
                    </Paper>
                </Container>
            </Container>
        </Box>
    );
}

export { MyAccountPage };