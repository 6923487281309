import { userConstants } from '../_constants';

const initialState = {
    loading: false,
    myAccountDetails: null,
    errorCode: null,
};

export function myAccount(state = initialState, action) {
    switch(action.type) {
        case userConstants.GET_MY_ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GET_MY_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                myAccountDetails: action.myAccountDetails
            };
        case userConstants.GET_MY_ACCOUNT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.UPDATE_MY_ACCOUNT_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.UPDATE_MY_ACCOUNT_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.UPDATE_MY_ACCOUNT_DETAILS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.SEND_RESET_PASSWORD_LINK_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.SEND_RESET_PASSWORD_LINK_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.SEND_RESET_PASSWORD_LINK_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.VALIDATE_RESET_PASSWORD_KEY_REQUEST:
            return {
                ...state,
                loading: true,
                errorCode: null
            };
        case userConstants.VALIDATE_RESET_PASSWORD_KEY_SUCCESS:
            return {
                ...state,
                loading: false,
                errorCode: null
            };
        case userConstants.VALIDATE_RESET_PASSWORD_KEY_FAILURE:
            return {
                ...state,
                loading: false,
                errorCode: action.error
            };
        case userConstants.RESET_PASSWORD_WITH_KEY_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.RESET_PASSWORD_WITH_KEY_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.RESET_PASSWORD_WITH_KEY_FAILURE:
            return {
                ...state,
                loading: false
            };
        case userConstants.DELETE_ACCOUNT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.DELETE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case userConstants.DELETE_ACCOUNT_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state
    }
}